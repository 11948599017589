import React from 'react';

import { Container } from './SupportPageLayout.styles';
import { SupportPageLayoutContent } from './SupportPageLayoutContent';
import { SupportPageLayoutSidebar } from './SupportPageLayoutSidebar';

type StaticComponents = {
  Content: typeof SupportPageLayoutContent;
  Sidebar: typeof SupportPageLayoutSidebar;
};

const SupportPageLayout: React.FC & StaticComponents = props => {
  return <Container>{props.children}</Container>;
};

SupportPageLayout.Content = SupportPageLayoutContent;
SupportPageLayout.Sidebar = SupportPageLayoutSidebar;

export default SupportPageLayout;
