import React from 'react';
import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  contentBox: {
    maxWidth: '1120px',
    width: '100%',
    padding: '0 91px',
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(0, 4),
    },
  },
}));

interface ContentBoxProps {
  children: React.ReactNode;
}

export const ContentBox: React.FC<ContentBoxProps> = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="center">
      <Grid item className={classes.contentBox}>
        {children}
      </Grid>
    </Grid>
  );
};
