import styled from '@emotion/styled';

export const GridAutoColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25%, 1fr));
  grid-gap: 8px;
`;

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
