import React from 'react';

import { formatMoney } from '@vizsla/utils';

import { useSelectedTeamTopFundraisersAverageDonations } from 'src/hooks';

import { LeaderBoardCard } from '../LeaderBoardCard';

export function MostAverageDonationsCard() {
  const { data, loading } = useSelectedTeamTopFundraisersAverageDonations();

  const top = React.useMemo(() => data[0], [data]);
  const user = React.useMemo(() => top?.attendee?.user ?? undefined, [top]);

  const formattedDonations = React.useMemo(() => {
    const groups: any | undefined = top?.allocatedDonations?.groups?.[0];
    const total = Number(groups?.average ?? 0);

    return formatMoney(total);
  }, [top]);

  const description = React.useMemo(() => {
    return `${formattedDonations} Average`;
  }, [formattedDonations]);

  return (
    <LeaderBoardCard
      title="Highest Average Donation"
      loading={loading}
      description={description}
      user={user}
    />
  );
}
