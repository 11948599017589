import React from 'react';
import { LinearProgress, linearProgressClasses, LinearProgressProps, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Shade } from '@vizsla/theme';

interface StylesParams {
  width?: string;
  height?: string;
}

const useStyles = makeStyles<Theme, StylesParams>(() => ({
  linearProgress: {
    width: '100%',
    [`&.${linearProgressClasses.root}`]: {
      height: ({ height }) => height,
      maxWidth: ({ width }) => width,
      borderRadius: 5,
    },
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: Shade.Gray[25],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      background: 'linear-gradient(90deg, #00FFC7 0%, #00D586 100%)',
    },
  },
}));
interface GradientLinearProgressProps {
  width?: string;
  height?: string;
}

export const GradientLinearProgress: React.FC<
  LinearProgressProps & GradientLinearProgressProps
> = ({ width = '440px', height = '2px', ...props }) => {
  const classes = useStyles({ width, height });

  return <LinearProgress className={classes.linearProgress} {...props} />;
};
