import React, { useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { isNil } from 'lodash';
import { useParams } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';

import { MODALS } from '@vizsla/constants';
import { useCurrentUser, useJoinTeamFundraising, useModal, useNotification } from '@vizsla/hooks';
import { Modal } from '@vizsla/components';
import { createTemplate } from '@vizsla/utils';
import { Attendee, TeamFundraising, USER_ATTENDEE_EXPERIENCE } from '@vizsla/graphql';

import { useTeamLandingData } from 'src/hooks/team';

export function ConfirmationJoiningTeamDialog() {
  const { teamId } = useParams();
  const [loading, setLoading] = React.useState(false);
  const { joinTeam, loading: loadingMutation } = useJoinTeamFundraising();
  const toast = useNotification();
  const { team, experience } = useTeamLandingData(teamId || '');

  const { isOpen, closeModal, args } = useModal(MODALS.JOINING_TEAM_MODAL);

  const onCancel = React.useCallback(() => {
    closeModal();
  }, [closeModal]);
  const { user, loading: loadingUser } = useCurrentUser();
  const [findAttendeeByFilter] = useLazyQuery(USER_ATTENDEE_EXPERIENCE);
  const existsAttendeeInExperience = async () => {
    if (!user.id || !experience) return false;
    const resultAttendee = await findAttendeeByFilter({
      variables: {
        userId: user.id,
        experienceId: experience,
      },
    });

    if (resultAttendee?.data.attendeesList) {
      return resultAttendee?.data.attendeesList.items[0];
    }

    return null;
  };

  const save = async () => {
    if (!team) return;

    try {
      const attendee = await existsAttendeeInExperience();

      if (!attendee) {
        toast.error('You must be registred as Attendee of the experience to join this team');
        onCancel();
        return;
      }

      const teamMembership = await joinTeam({ attendee, team: team as TeamFundraising });

      if (teamMembership) {
        toast.success(`Now you're part of the "${team.name}" team`);
        onCancel();
        return;
      }

      throw new Error(`Team "${team.name}" could not be selected`);
    } catch (err) {
      console.error(err);
      toast.error('Something fail when trying to select the team');
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.Content>
        <Grid
          sx={{ padding: theme => theme.spacing(4, 0, 0, 0) }}
          container
          justifyContent="center"
        >
          <Typography variant="body1">
            <span>{`Are you sure you want to join team ${
              args?.objectType ? `the ${args?.objectType}` : ''
            }`}</span>
            <b>{` "${team?.name ?? 'this object'}" `}</b>?
          </Typography>
        </Grid>
      </Modal.Content>
      <Modal.Actions>
        <Modal.Action type="secondary" content="Cancel" onAction={onCancel} />
        <Modal.Action
          type="primary"
          content="Join"
          onAction={() => save()}
          loading={loadingMutation}
        />
      </Modal.Actions>
    </Modal>
  );
}
