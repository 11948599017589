import React from 'react';
import { Skeleton } from '@mui/material';

import {
  FormSpy,
  Field,
  TextField,
  useFormState,
  CheckboxField,
  useForm,
} from '@vizsla/components';
import { ShoppingCartRegistrationData, useShoppingCart } from '@vizsla/hooks';

import { useSelectedExperience } from 'src/hooks';

import {
  FormContainer,
  FormControl,
  FormSection,
  FormTitle,
  HTMLRender,
} from './ExperienceWaiverForm.style';

export interface FormValues {
  waiverAcceptance: boolean;
  waiverSignature: string;
}

const SCROLLABLE_THRESHOLD = 50;

export function ExperienceWaiverForm() {
  const { change } = useForm();
  const { values } = useFormState<FormValues>();
  const { items: cart, editMany } = useShoppingCart();
  const { data: experience, loading: fetching } = useSelectedExperience();

  const renderRef = React.useRef<HTMLDivElement>(null);

  const [canSign, setCanSign] = React.useState(false);

  React.useEffect(() => {
    const target = renderRef?.current;

    if (target) {
      validateCanSign(target);
    }
  }, [renderRef]);

  React.useEffect(() => {
    if (values.waiverAcceptance && values.waiverSignature) {
      const newCart = cart
        .filter(i => i.type === 'registration')
        .map(item => {
          if (item.type === 'registration' && item.attendee) {
            item.attendee.waiverSignature = values.waiverSignature;
          }

          return item;
        });

      editMany(newCart);
    }
  }, [values]);

  React.useEffect(() => {
    if (!values.waiverAcceptance) {
      const registration = cart.find(
        i => i.type === 'registration',
      ) as ShoppingCartRegistrationData;
      const signature = registration.attendee?.waiverSignature;
      if (signature) {
        change('waiverAcceptance', Boolean(signature));
        change('waiverSignature', signature);
      }
    }
  }, [cart, change, values.waiverAcceptance]);

  const validateCanSign = (target: HTMLDivElement) => {
    const scrollable = target.scrollHeight - target.clientHeight;

    const hasEnoughSpace = scrollable > SCROLLABLE_THRESHOLD;
    const hasEnded = target.scrollTop > SCROLLABLE_THRESHOLD;

    const canSign = !hasEnoughSpace || hasEnded;

    setCanSign(prev => prev || canSign);
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    validateCanSign(event.target as HTMLDivElement);
  };

  return (
    <FormSpy>
      {() => (
        <FormContainer>
          <FormTitle>Waiver</FormTitle>

          {fetching && <Skeleton height={220} variant="rectangular" />}

          {experience?.waiverTextBody && !fetching && (
            <HTMLRender ref={renderRef} onScroll={handleScroll} html={experience.waiverTextBody} />
          )}

          <FormSection>
            <FormControl>
              <Field
                name="waiverAcceptance"
                label="I accept the waiver agreement *"
                component={CheckboxField}
                disabled={!canSign}
                required
                fullWidth
              />
            </FormControl>

            {values.waiverAcceptance && (
              <FormControl>
                <Field
                  label="Signature"
                  name="waiverSignature"
                  component={TextField}
                  required
                  fullWidth
                />
              </FormControl>
            )}
          </FormSection>
        </FormContainer>
      )}
    </FormSpy>
  );
}
