import React from 'react';
import { ThemeProvider as MaterialThemeProvider } from '@mui/material';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';

import { theme } from '@vizsla/theme';

export function BaseThemeProvider(props: React.PropsWithChildren<unknown>) {
  return (
    <MaterialThemeProvider theme={theme}>
      <EmotionThemeProvider theme={theme}>{props.children}</EmotionThemeProvider>
    </MaterialThemeProvider>
  );
}
