import styled from '@emotion/styled';
import { Tab } from '@mui/material';
import { AccountCircleOutlined, CheckCircle, Error as ErrorCircle } from '@mui/icons-material';

import { FontSize, FontWeight, PaletteColor, Shade } from '@vizsla/theme';

/// Container

interface ContainerProps {
  active: boolean;
}

export const Container = styled(Tab)<ContainerProps>`
  font-weight: ${FontWeight.Regular};
  font-size: ${FontSize.S};
  text-transform: none;
  color: ${p => (p.active ? p.theme.palette.primary.main : Shade.Gray[200])};
  opacity: 1;
`;

/// AccountIcon

export const AccountIcon = styled(AccountCircleOutlined)`
  font-size: ${p => p.theme.spacing(8)};
  margin-bottom: ${p => p.theme.spacing(1)};
  position: relative;
`;

/// CheckIcon

export const CheckIcon = styled(CheckCircle)`
  font-size: ${p => p.theme.spacing(3)};
  color: ${PaletteColor.SuccessMain};
  background-color: white;
  border-radius: 1rem;
  position: absolute;
  z-index: 1;
  top: 0.5rem;
  right: 1rem;
`;

/// AlertIcon

export const AlertIcon = styled(ErrorCircle)`
  font-size: ${p => p.theme.spacing(3)};
  color: ${PaletteColor.ErrorMain};
  background-color: white;
  border-radius: 1rem;
  position: absolute;
  z-index: 1;
  top: 0.5rem;
  right: 1rem;
`;
