import { gql } from '@apollo/client';

import { Attendee, Experience } from '@vizsla/graphql';

export const EXPERIENCE_LANDING_FRAGMENT = gql`
  fragment ExperienceLandingFragment on Experience {
    id
    name
    experienceType
    experienceCategory
    startDate
    endDate
    waiverAllowMultipleAttendeeConsent
    waiverTextBody
    registrationOptions {
      items {
        id
        name
        pricingSettings {
          id
          pricingType
          pricingTiers {
            items {
              id
              price
              startDate
              endDate
            }
          }
          pricingEnabled
        }
      }
    }
    campaign {
      id
      name
      fundraisingGoal
      totalRaised
      vizslaOrganization {
        id
      }
      contactEmail
      contactPhone {
        number
      }
    }
    donations {
      items {
        amount
        order {
          number
        }
      }
    }
    venues {
      items {
        id
        name
        notes
        address
      }
    }
    fundraisingSettings {
      donationSettings {
        feesMessage
        feesEnabled
      }
    }
    isStoreEnabled
  }
`;

export const EXPERIENCE_LANDING_QUERY = gql`
  query ExperienceLanding($id: ID!) {
    experience(id: $id) {
      ...ExperienceLandingFragment
    }
  }
  ${EXPERIENCE_LANDING_FRAGMENT}
`;

export const EXPERIENCE_REGISTRATION_OPTION_FRAGMENT = gql`
  fragment ExperienceRegistrationOptionFragment on RegistrationOption {
    id
    name
    distance
    unitOfMeasure
    startWaveOrder
    description
    fundraisingMinimum
    ageRequirement
    ageRequirementAsOfDate
    attendeesPerRegistration
    maximumRegistrationPerOrder
    groupRegistrationMinimum
    groupRegistrationMaximum
    maximumAttendees
    fundraisingMinimum
    checkpointAmount
    checkpointDate
    checkpointNotification
    registrationStartDate
    registrationEndDate
    attendees {
      count
    }
    pricingSettings {
      id
      allowCostToBeFundraisingCredit
      pricingEnabled
      pricingType
      pricingTiers {
        items {
          id
          price
          taxDeductibleCurrencyAmount
          taxDeductiblePercentsAmount
          taxDeductibleType
          startDate
          endDate
        }
        count
      }
    }
  }
`;
export const EXPERIENCE_DEFAULT_CAMPAIGN_QUESTIONS_FRAGMENT = gql`
  fragment ExperienceDefaultCampaignQuestionFragment on DefaultCampaignQuestion {
    id
    isEnabled
    isRequired
    shouldDisplay
    order
    question {
      id
      title
      name
      category
      type
      format
      options
      userGroup
      isMandatory
    }
  }
`;
export const EXPERIENCE_CUSTOM_CAMPAIGN_QUESTIONS_FRAGMENT = gql`
  fragment ExperienceCustomCampaignQuestionFragment on CustomCampaignQuestion {
    id
    title
    isEnabled
    isRequired
    userGroup
    type
    format
    options
    order
  }
`;

export const EXPERIENCE_REGISTRATION_QUERY = gql`
  query ExperienceRegistrationQuery($id: ID!) {
    experience(id: $id) {
      id
      name
      waiverEnabled # DONT
      waiverAllowMultipleAttendeeConsent # DONT
      waiverTextBody # DONT
      isStoreEnabled
    }
  }
`;

export interface ExperienceRegistrationQuery {
  experience?: Experience;
}

export interface ExperienceRegistrationQueryVariables {
  id: string;
}

/// EXPERIENCE_ATTENDEES_QUERY

export const EXPERIENCE_ATTENDEES_QUERY = gql`
  query ExperienceAttendees($id: ID!) {
    attendees: attendeesList(filter: { experience: { id: { equals: $id } } }) {
      count
      items {
        id

        user {
          id
          email
        }
      }
    }
  }
`;

export interface ExperienceAttendeesQuery {
  attendees: {
    count: number;
    items: Attendee[];
  };
}

export interface ExperienceAttendeesQueryVariables {
  id: string;
}
