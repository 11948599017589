import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { DonationFormValues } from '@vizsla/components';
import { useTeamFundraisingLazyQuery } from '@vizsla/graphql';
import { useCurrentUser, useNotification, useShoppingCart } from '@vizsla/hooks';
import { validateWithSchema } from '@vizsla/utils';

import { DONATION_FORM_SCHEMA } from 'src/constants/validationSchemas';
import { useCheckout, useCheckoutPage, useSelectedTeamFundraising } from 'src/hooks';

export function useTeamFundraisingDonate() {
  const {
    data: team,
    loading: fetching,
    setData: setTeam,
    setLoading: setTeamFetching,
  } = useSelectedTeamFundraising();

  const { add: addToCart } = useShoppingCart();
  const { user } = useCurrentUser();
  const { teamId } = useParams();
  const [checkout] = useCheckout();
  const { openTeam: openCheckout } = useCheckoutPage();

  const toaster = useNotification();

  const [executeTeamQuery] = useTeamFundraisingLazyQuery();

  const fetchTeamByParams = async () => {
    if (!teamId) return;

    setTeamFetching(true);

    const response = await executeTeamQuery({
      variables: {
        filter: {
          OR: [{ id: { equals: teamId } }, { webLink: { equals: teamId } }],
        },
      },
    });

    const team = response.data?.team?.items?.[0];

    if (team) {
      setTeam(team);
      setTeamFetching(false);
    }
  };

  useEffect(() => {
    fetchTeamByParams();
  }, [teamId]);

  const handleSubmit = async (values: DonationFormValues) => {
    const price = values.amount ?? 0;
    const makeAnonymous = values.makeAnonymous ?? false;

    if (!team?.id) {
      toaster.error('Team must be defined');
      return;
    }

    if (!team?.experience?.id) {
      toaster.error('Experience must be defined.');
      return;
    }

    if (!user?.id) {
      toaster.error('User must be defined.');
      return;
    }

    addToCart({
      type: 'donation',
      donor: { id: user.id },
      allocation: { type: 'team', id: team.id },
      message: values.message,
      makeAnonymous,
      price,
    });

    const experienceId = team.experience.id;
    openCheckout({
      id: team.id,
      experience: { id: experienceId },
    });
    checkout(experienceId);
  };

  const validateForm = (values: DonationFormValues) => {
    return validateWithSchema(DONATION_FORM_SCHEMA, values);
  };

  return {
    team,
    fetching,

    validateForm,
    handleSubmit,
  };
}
