import { useExperienceId as sharedUseExperienceId } from '@vizsla/hooks';

/** @deprecated use hook from &#64;vizsla/hooks package */
export const useExperienceId = sharedUseExperienceId;

export { useExperienceById } from './useExperienceById';
export { useCurrentExperience } from './useCurrentExperience';
export { useSelectedExperience } from './selectedExperience';
export { useSelectedExperienceAttendees } from './selectedExperienceAttendees';
export { useOpenExperiencePages } from './openExperiencePages';
