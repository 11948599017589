import styled from '@emotion/styled';

import { SUPPORT_LAYOUT_IMAGE } from 'src/assets';

/// Container

const CONTAINER_OVERLAY_OPACITY = 0.9;

export const Container = styled.div`
  max-width: 100vw;
  display: flex;
  align-items: flex-start;
  padding: ${p => p.theme.spacing(4)};
  gap: ${p => p.theme.spacing(4)};
  position: relative;
  box-sizing: border-box;
  background-size: cover;
  background-blend-mode: overlay;
  background-image: url(${SUPPORT_LAYOUT_IMAGE}),
    linear-gradient(
      rgba(255, 255, 255, ${CONTAINER_OVERLAY_OPACITY}),
      rgba(255, 255, 255, ${CONTAINER_OVERLAY_OPACITY})
    );

  @media (max-width: 1170px) {
    align-items: initial;
    flex-direction: column-reverse;
  }
`;
