import styled from '@emotion/styled';
import { Typography } from '@mui/material';

/// Container

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${p => p.theme.spacing(2)};
`;

/// Title

export const Title = styled(Typography)`
  text-align: center;
`;

Title.defaultProps = {
  variant: 'h2',
};

/// SubTitle

export const SubTitle = styled(Typography)`
  letter-spacing: ${p => p.theme.spacing(0.3)};
  font-weight: ${p => p.theme.typography.fontWeightLight};
  text-transform: uppercase;
  text-align: center;
`;

SubTitle.defaultProps = {
  variant: 'subtitle2',
};
