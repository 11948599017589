import React from 'react';
import { styled } from '@mui/styles';

import { File } from '@vizsla/graphql';

interface HeaderLogoProps {
  logo?: File | null;
}

const StyledLogoImage = styled('img')(() => ({
  maxWidth: 100,
  maxHeight: 48,
  marginRight: '-10px',
}));

export const HeaderLogo: React.FC<HeaderLogoProps> = ({ logo }) => {
  if (!logo) {
    return null;
  }

  return (
    <div>
      <StyledLogoImage src={logo?.downloadUrl || ''} alt="Campaign Logo" />
    </div>
  );
};
