import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';

/// FormContainer

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(3)};
`;

/// FormSection

export const FormSection = styled(Grid)``;

FormSection.defaultProps = {
  container: true,
  spacing: 2,
};

/// FormTitle

export const FormTitle = styled(Typography)``;

FormTitle.defaultProps = {
  variant: 'h5',
};

/// FormControl

export const FormControl = styled(Grid)``;

FormControl.defaultProps = {
  item: true,
  xs: 6,
  md: 12,
  lg: 6,
};
