import React from 'react';

import { useSelectedTeamTopFundraisersDonors } from 'src/hooks';

import { LeaderBoardCard } from '../LeaderBoardCard';

export function MostDonorCard() {
  const { data, loading } = useSelectedTeamTopFundraisersDonors();

  const top = React.useMemo(() => data[0], [data]);
  const user = React.useMemo(() => top?.attendee?.user ?? undefined, [top]);

  const donorsCount = React.useMemo(() => {
    const groups: any | undefined = top?.allocatedDonations?.groups?.[0];
    const count = Number(groups?.donors ?? 0);

    return count;
  }, [top]);

  const description = React.useMemo(() => {
    return `${donorsCount} Donors`;
  }, [donorsCount]);

  return (
    <LeaderBoardCard title="Most Donor" loading={loading} description={description} user={user} />
  );
}
