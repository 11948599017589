import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Typography, FormControl, TextField } from '@mui/material';

import { useModal, useNotification } from '@vizsla/hooks';
import { Modal } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';

export const EarlyBird = () => {
  const { isOpen, closeModal, args } = useModal(MODALS.EARLY_BIRD);
  const notification = useNotification();
  const [code, setCode] = React.useState('');
  const [experienceId, setExperienceId] = React.useState(null);

  const handleChange = event => {
    setCode(event.target.value);
  };

  const VERIFY_EARLY_CODE = gql`
    query VerifyEarlyCode($code: String!, $experienceId: ID!) {
      verifyEarlyBirdCode(filter: { code: $code, experienceId: $experienceId }) {
        valid
      }
    }
  `;

  const { data, loading, refetch } = useQuery(VERIFY_EARLY_CODE, {
    variables: { code, experienceId },
  });
  React.useEffect(() => {
    if (args?.setAccess?.experienceId) {
      setExperienceId(args?.setAccess?.experienceId);
    }
  }, [args]);
  const onClick = () => {
    if (data?.verifyEarlyBirdCode?.valid) {
      args.setAccess.setAccess(true);
      notification.success('Valid Code');
    } else {
      notification.error('Invalid Code');
    }
  };

  return (
    <React.Fragment>
      <Modal isOpen={isOpen} onClose={closeModal} fullWidth>
        <Modal.Title>
          <Typography textAlign="center" variant="h2">
            Early Bird
          </Typography>
        </Modal.Title>
        <Modal.Content>
          <FormControl fullWidth>
            <TextField
              id="EarlyBird"
              label="Early Bird Code"
              value={code}
              onChange={handleChange}
            />
            <Modal.Actions>
              <Modal.Action type="secondary" content="Cancel" onAction={closeModal} />
              <Modal.Action
                type="primary"
                content="Continue"
                onAction={onClick}
                loading={false}
                disabled={false}
              />
            </Modal.Actions>
          </FormControl>
        </Modal.Content>
      </Modal>
    </React.Fragment>
  );
};
