import React from 'react';
import * as yup from 'yup';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Skeleton } from '@mui/material';

import { Form, FORM_ARRAY_MUTATOR } from '@vizsla/components';
import { useCurrentUser } from '@vizsla/hooks';
import { validateWithSchema } from '@vizsla/utils';

import { ExperiencePurchaseLayout } from 'src/layouts';
import {
  useAvailableRegistrationOptions,
  useOpenExperiencePages,
  useSelectedExperience,
} from 'src/hooks';

import {
  Button,
  Buttons,
  FormContainer,
  OptionList,
  StepTitle,
} from './ExperienceOptionsStep.style';
import { ExperienceOptionItem } from './ExperienceOptionItem';
import { ExperienceOptionModal } from './ExperienceOptionModal/ExperienceOptionModal';
import { NotAvaialbe } from './NotAvailable/NotAvailable';

interface FormValues {
  options?: Array<{
    counter: number;
  }>;
}

const SCHEMA = yup.object({
  options: yup.array(
    yup.object({
      counter: yup.number(),
    }),
  ),
});

const INITIAL: FormValues = {
  options: [],
};

export function ExperienceOptionsStep() {
  const [access, setAccess] = React.useState(false);
  const { experienceId } = useParams();
  const { fullName, loading: fetchingUser } = useCurrentUser();
  const { loading: fetchingExperience } = useSelectedExperience();
  const { openAttendeesForm } = useOpenExperiencePages();
  const currentDate = new Date();
  const { data: options, loading: fetchingRegistrations } = useAvailableRegistrationOptions({
    experience: {
      id: { equals: experienceId },
    },
  });
  const loading = React.useMemo(() => {
    return fetchingUser || fetchingExperience || fetchingRegistrations;
  }, [fetchingUser, fetchingExperience, fetchingRegistrations]);

  const handleSubmit = () => {
    openAttendeesForm(experienceId);
  };

  const validateForm = async (values: FormValues) => {
    const errors = await validateWithSchema(SCHEMA, values);

    const options = values.options ?? [];
    const selectedOptions = options.filter(o => o.counter > 0);

    if (selectedOptions.length === 0) {
      return {
        status: 'NOT_FILLED',
        ...errors,
      };
    }

    return errors;
  };

  if (
    (new Date(options[0]?.experience?.startDate) > currentDate && !access) ||
    (new Date(options[0]?.experience?.endDate ?? '') < currentDate && !access)
  ) {
    return (
      <React.Fragment>
        <NotAvaialbe setAccess={setAccess} experienceId={experienceId} />
      </React.Fragment>
    );
  }
  return (
    <ExperiencePurchaseLayout>
      <StepTitle>Right on, {fullName}! Next, select your option.</StepTitle>
      <ExperienceOptionModal />
      <Form
        initialValues={INITIAL}
        mutators={{ ...FORM_ARRAY_MUTATOR }}
        validate={validateForm}
        onSubmit={handleSubmit}
      >
        {form => (
          <FormContainer>
            {fetchingRegistrations && (
              <OptionList>
                <Skeleton height={80} variant="rectangular" />
                <Skeleton height={80} variant="rectangular" />
                <Skeleton height={80} variant="rectangular" />
              </OptionList>
            )}

            <OptionList>
              {options.map((option, index) => (
                <ExperienceOptionItem key={option.id} index={index} {...option} />
              ))}
            </OptionList>

            <Buttons>
              <Button disabled={!form.valid || loading} onClick={form.handleSubmit}>
                Continue
              </Button>
            </Buttons>
          </FormContainer>
        )}
      </Form>
    </ExperiencePurchaseLayout>
  );
}
