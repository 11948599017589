import React from 'react';
import { makeStyles } from '@mui/styles';
import { Toolbar, Theme, Paper, IconButton, styled } from '@mui/material';

import { Button } from '@vizsla/components';
import { TemplateType } from '@vizsla/types';
import { HeaderLogoAlignType } from '@vizsla/constants';
import { useModal, useAppAuth } from '@vizsla/hooks';

import { MODALS } from 'src/constants/modals';
import { useCurrentPublicCampaign } from 'src/hooks/campaign';
import { useCurrentExperience } from 'src/hooks/experience';
import { useLandingState } from 'src/hooks/landing';

import { HeaderLogo } from './HeaderLogo';
import { AvatarImage } from './AvatarImage';

interface StylesParams {
  headerBackground: string;
}

const useStyles = makeStyles<Theme, StylesParams>(theme => ({
  root: {
    gridArea: 'header',
    zIndex: 2,
  },
  personalButton: {
    marginLeft: 'auto',
    marginRight: '0',
  },
}));

interface HeaderProps {
  styles: Record<string, any>;
}

type ToolbarProps = {
  headerLogoAlign: HeaderLogoAlignType;
  headerBackground: string;
};

const StyledHeaderLogoWraper = styled('div')(() => ({
  position: 'absolute',
}));

const StyledToolbar = styled(Toolbar)((props: ToolbarProps) => ({
  justifyContent: `${
    props?.headerLogoAlign === HeaderLogoAlignType.left ? 'flex-start' : 'center'
  }`,
  background: `${props?.headerBackground}`,
}));

export const Header: React.FC<HeaderProps> = ({ styles }) => {
  const { headerBackground } = styles;
  const classes = useStyles({ headerBackground });
  const { logout, isAuthenticated } = useAppAuth();
  const { openModal } = useModal();
  const { campaign } = useCurrentPublicCampaign();
  const { experience } = useCurrentExperience();
  const { templates } = useLandingState();

  const onOpen = () => openModal(MODALS.LOGIN_MODAL);

  const experienceLandingTemplate = templates?.find(
    template => template?.templateType?.type === TemplateType.EXPERIENCE,
  );
  const experienceLandingStructure = experienceLandingTemplate?.structure?.appSchema;

  const headerLogoImage = experience?.experienceLogoImage || campaign?.logo;
  const headerLogoAlign = experienceLandingStructure?.theme?.headerLogoAlign;

  const openProfile = () => {
    // TODO: Open profile account in `client-consumer`
  };

  return (
    <Paper className={classes.root} square elevation={1}>
      <StyledToolbar headerLogoAlign={headerLogoAlign} headerBackground={headerBackground}>
        <StyledHeaderLogoWraper>
          <HeaderLogo logo={headerLogoImage} />
        </StyledHeaderLogoWraper>

        <div className={classes.personalButton}>
          {isAuthenticated ? (
            <Button color="warning" onClick={logout}>
              Log Out
            </Button>
          ) : (
            <Button onClick={onOpen}>Log In</Button>
          )}

          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={openProfile}
            color="inherit"
          >
            <AvatarImage />
          </IconButton>
        </div>
      </StyledToolbar>
    </Paper>
  );
};
