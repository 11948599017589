import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import {
  TOP_DONATION_TEAM_FUNDRAISERS,
  IndividualFundraising,
  IndividualFundraisingListResponse,
} from '@vizsla/graphql';

import { useSelectedTeamFundraiser } from '..';

interface TopDonationsQuery {
  fundraisers: IndividualFundraisingListResponse;
}

interface TopDonationsQueryVariables {
  teamId: string;
}

export function useSelectedTeamTopFundraisersDonations() {
  const [data, setData] = useState<IndividualFundraising[]>([]);
  const [loading, setLoading] = useState(false);

  const [executeQuery] = useLazyQuery<TopDonationsQuery, TopDonationsQueryVariables>(
    TOP_DONATION_TEAM_FUNDRAISERS,
  );

  const { data: team } = useSelectedTeamFundraiser();

  async function refetch() {
    if (!team?.id) return;

    setLoading(true);

    const response = await executeQuery({
      variables: { teamId: team.id },
    });

    const data = response.data?.fundraisers?.items;
    const dataTeam = data?.slice();

    if (Array.isArray(dataTeam)) {
      const sorted = dataTeam.sort((first, second) => {
        const firstGroup: any = first.allocatedDonations?.groups?.at(0);
        const secondGroup: any = second.allocatedDonations?.groups?.at(0);

        const firstTotal = firstGroup?.total ?? 0;
        const secondTotal = secondGroup?.total ?? 0;

        return secondTotal - firstTotal;
      });

      setData(sorted);
      setLoading(false);
    }
  }

  useEffect(() => {
    refetch();
  }, [team]);

  return {
    data,
    loading,

    refetch,
  };
}
