import styled from '@emotion/styled';
import { IconButton, Typography, Button } from '@mui/material';

import { BorderRadius, Shade } from '@vizsla/theme';

/// Container

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min
  justify-content: center;
  align-items: center;
  gap: ${p => p.theme.spacing(3)};
`;

/// Section

export const Section = styled.div`
  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing(1)};
`;

/// Divider

export const Divider = styled.hr`
  max-width: 35rem;
  width: 80%;
  height: 1px;
  background-color: ${Shade.Gray[100]};
  border: none;
`;

/// Text

export const Text = styled(Typography)`
  color: ${Shade.Gray[500]};
`;

Text.defaultProps = {
  variant: 'body2',
};

/// Logo

export const Logo = styled.img`
  max-height: ${p => p.theme.spacing(8)};
`;

/// SubTitle

export const SubTitle = styled(Text)`
  letter-spacing: ${p => p.theme.spacing(0.2)};
  font-weight: ${p => p.theme.typography.fontWeightBold};
  text-transform: uppercase;
  text-align: center;
  color: #202b38;
  margin-top: ${p => p.theme.spacing(1)};
`;

SubTitle.defaultProps = {
  variant: 'subtitle2',
};

/// Title

export const Title = styled(Typography)`
  text-align: center;
`;

Title.defaultProps = {
  variant: 'h2',
};

/// SocialContainer

export const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing(2)};
`;

export const Icon = styled(IconButton)`
  position: absolute;
  right: ${p => p.theme.spacing(1)};
  top: ${p => p.theme.spacing(1)};
  color: ${p => p.theme.palette.grey[500]};
`;

export const CopyButton = styled(Button)`
  background-color: ${p => p.theme.palette.secondary.light};
  border-radius: ${BorderRadius.M};
  height: ${p => p.theme.spacing(5)};
  width: ${p => p.theme.spacing(18)};
`;
