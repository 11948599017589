import React from 'react';

import { ThanksPage } from 'src/components';
import { useSelectedFundraser } from 'src/hooks';

export function FundraiserDonationThanks() {
  const { data: fundraiser } = useSelectedFundraser();

  const name = React.useMemo(() => fundraiser?.title ?? 'A fundraiser', [fundraiser]);
  const image = React.useMemo(() => fundraiser?.logo?.downloadUrl ?? undefined, [fundraiser]);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return <ThanksPage title={name} subtitle="You had donated successfully to" uri={image} />;
}
