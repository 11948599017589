import styled from '@emotion/styled';

/// Content

export const Content = styled.div`
  margin-top: ${p => p.theme.spacing(5)};
`;

/// LoadingContent

export const LoadingContent = styled.div`
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

/// Buttons

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
