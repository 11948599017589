import React from 'react';
import { Avatar, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { Maybe } from '@vizsla/graphql';
import { formatMoney, calcPercentage } from '@vizsla/utils';

import { GradientLinearProgress, GradientDivider, LandingButton } from 'src/components/shared';
import { ParticipatingType } from 'src/constants/participating';

const MAIN_COLOR = '#EC308C';

const useStyles = makeStyles({
  boldText: {
    fontWeight: 'bold',
  },
});

type ParticipatingCardProps = {
  type: ParticipatingType;
  name: Maybe<string | undefined>;
  fundraisingGoal: Maybe<number | undefined>;
  totalRaised: Maybe<number | undefined>;
  img?: string | undefined;
};

export const ParticipatingCard: React.FC<ParticipatingCardProps> = ({
  type,
  name,
  fundraisingGoal,
  totalRaised,
  img,
}) => {
  const classes = useStyles();

  const percentage = calcPercentage(totalRaised || 0, fundraisingGoal || 0);

  const isTeamCaptain = type === ParticipatingType.teamCamptain;
  const isTeam = type === ParticipatingType.myTeam;

  const avatarVariant = isTeam || isTeamCaptain ? 'circular' : 'square';
  const avatarWidth = isTeam || isTeamCaptain ? 56 : 150;
  const avatarHeight = isTeam || isTeamCaptain ? 56 : 80;

  return (
    <React.Fragment>
      <Grid width="100%" item marginTop="25px">
        <GradientDivider color="#e9e9e9" />
      </Grid>
      <Grid item marginTop="25px">
        <Grid flexDirection="column" alignItems="center" container>
          <Grid item>
            <Typography variant="tabs">{type}</Typography>
          </Grid>
          <Grid item marginTop="20px">
            <Avatar
              src={img}
              sx={{ width: avatarWidth, height: avatarHeight }}
              variant={avatarVariant}
            />
          </Grid>
          <Grid item marginTop="10px">
            <Typography variant="h5">{name}</Typography>
          </Grid>
          <Grid marginTop="8px" maxWidth="220px" width="100%" item>
            <GradientLinearProgress height="8px" variant="determinate" value={percentage} />
          </Grid>
          <Grid marginTop="6px" item>
            <Typography variant="body1">
              <span className={classes.boldText}>{formatMoney(totalRaised)}</span>
              {` of a goal of `}
              <span className={classes.boldText}>{formatMoney(fundraisingGoal)}</span>
            </Typography>
          </Grid>
          {isTeam && (
            <Grid item marginTop="21px">
              <LandingButton themeColor={MAIN_COLOR}>JOIN TEAM</LandingButton>
            </Grid>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
