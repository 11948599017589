import React from 'react';
import { StyledEngineProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/lab';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import { ErrorBoundary } from 'react-error-boundary';
import { Auth, AUTH_STRATEGIES, ISubscribableAuthClient } from '@8base/auth';
import { AppProvider } from '8base-react-sdk';
import { InMemoryCacheConfig } from '@apollo/client';
import errorCodes from '@8base/error-codes';

import { AuthProvider, ModalsProvider } from '@vizsla/providers';
import { ErrorFallback } from '@vizsla/components';

import { BaseThemeProvider } from './BaseThemeProvider';

const {
  REACT_APP_ENDPOINT = '',
  REACT_APP_WORKSPACE_ID = '',
  REACT_APP_WORKSPACE_ENV = '',
  REACT_APP_AUTH_REGION = '',
  REACT_APP_AUTH_POOL_ID = '',
  REACT_APP_AUTH_CLIENT_ID = '',
} = process.env;

const cacheOptions: InMemoryCacheConfig = {};

const authInfo = {
  workspaceId: REACT_APP_WORKSPACE_ID,
  workspaceEnv: REACT_APP_WORKSPACE_ENV,
  authRegion: REACT_APP_AUTH_REGION,
  authUserPoolId: REACT_APP_AUTH_POOL_ID,
  authClientId: REACT_APP_AUTH_CLIENT_ID,
};

const authClient = Auth.createClient(
  {
    strategy: AUTH_STRATEGIES.WEB_OAUTH,
    subscribable: true,
  },
  {
    authorize: () => undefined,
  },
);

export const ApplicationProvider: React.FC = ({ children }) => {
  const onRequestSuccess = () => {};

  const onRequestError = ({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(error => {
        if (error?.code === errorCodes.UserNotFoundErrorCode) {
          authClient.purgeState();
        }
        console.error(error);
      });
    }
  };

  return (
    <AppProvider
      authClient={authClient as ISubscribableAuthClient}
      cacheOptions={cacheOptions}
      onRequestSuccess={onRequestSuccess}
      onRequestError={onRequestError as any}
      uri={REACT_APP_ENDPOINT}
      withSubscriptions
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <StyledEngineProvider injectFirst>
            <BaseThemeProvider>
              <ModalsProvider>
                <AuthProvider authInfo={authInfo}>{children}</AuthProvider>
              </ModalsProvider>
            </BaseThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </ErrorBoundary>
    </AppProvider>
  );
};
