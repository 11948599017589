import React from 'react';
import { styled } from '@mui/styles';

import { Button, ButtonProps } from '@vizsla/components';
import { PaletteColor } from '@vizsla/theme';

const StyledSubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#FFCA01 !important',
  margin: '0 !important',
  width: '100%',
  borderRadius: '28px !important',
  height: '56px',
  color: `${PaletteColor.BlackText} !important`,
  '&:hover': {
    backgroundColor: '#FFC107 !important',
  },
  '&:active': {
    backgroundColor: '#FFA000 !important',
  },
  '&:disabled': {
    boxShadow: 'none',
    backgroundColor: '#FFE082 !important',
  },
}));

export const AuthSubmitButton: React.FC<ButtonProps> = props => {
  return <StyledSubmitButton {...props} />;
};
