import styled from '@emotion/styled';
import { Typography } from '@mui/material';

import { Button as ButtonBase } from '@vizsla/components';

/// Content

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(3)};
`;

/// StepTitle

export const StepTitle = styled(Typography)`
  text-align: center;
  font-weight: lighter;
`;

StepTitle.defaultProps = {
  variant: 'h5',
};

/// Buttons

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

/// Button

export const Button = styled(ButtonBase)`
  margin: 0;
`;
