import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AppRouter } from './providers';
import { ConfirmEmailModal, LoginModal, SignupModal } from './modals';

const App: React.FC = () => {
  const [firstRoute, setFirstRoute] = React.useState<string | null>(null);
  const { location } = window;

  React.useEffect(() => {
    setFirstRoute(location.pathname);
  }, []);

  return (
    <BrowserRouter>
      <AppRouter />
      <LoginModal firstRoute={firstRoute} />
      <SignupModal />
      <ConfirmEmailModal />
    </BrowserRouter>
  );
};

export default App;
