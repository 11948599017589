import React from 'react';

import { ThanksPage } from 'src/components';
import { useOpenExperiencePages, useSelectedExperience } from 'src/hooks';

export function ExperienceThanks() {
  const { data: experience } = useSelectedExperience();
  const { openLanding } = useOpenExperiencePages();

  const name = React.useMemo(() => experience?.name ?? 'Experience', [experience]);

  React.useEffect(() => {
    if (!experience) {
      goBack();
      return;
    }

    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const goBack = () => {
    openLanding(experience?.id ?? undefined);
  };

  return <ThanksPage title={name} subtitle="You have been registered to" />;
}
