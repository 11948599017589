import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { DonationFormValues } from '@vizsla/components';
import { useIndividualFundraisingLazyQuery } from '@vizsla/graphql';
import { useCurrentUser, useNotification, useShoppingCart } from '@vizsla/hooks';
import { validateWithSchema } from '@vizsla/utils';

import { DONATION_FORM_SCHEMA } from 'src/constants/validationSchemas';
import { useCheckout, useCheckoutPage, useSelectedFundraser } from 'src/hooks';

export function useTeamFundraisingDonate() {
  const {
    data: fundraiser,
    loading: fetching,
    setData: setFundraiser,
    setLoading: setFundraiserFetching,
  } = useSelectedFundraser();

  const { add: addToCart } = useShoppingCart();
  const { user } = useCurrentUser();
  const { fundraiserId } = useParams();
  const [checkout] = useCheckout();
  const { openFundraiser: openCheckout } = useCheckoutPage();

  const toaster = useNotification();

  const [executeFundraiserQuery] = useIndividualFundraisingLazyQuery();

  const refetch = async () => {
    if (!fundraiserId) return;

    setFundraiserFetching(true);

    const response = await executeFundraiserQuery({
      variables: {
        filter: {
          OR: [{ id: { equals: fundraiserId } }, { webLink: { equals: fundraiserId } }],
        },
      },
    });

    const team = response.data?.fundraisers?.items?.[0];

    if (team) {
      setFundraiser(team);
      setFundraiserFetching(false);
    }
  };

  useEffect(() => {
    refetch();
  }, [fundraiserId]);

  const handleSubmit = async (values: DonationFormValues) => {
    const price = values.amount ?? 0;
    const makeAnonymous = values.makeAnonymous ?? false;

    if (!fundraiser?.id) {
      toaster.error('Fundraiser must be defined');
      return;
    }

    if (!fundraiser?.attendee?.experience?.id) {
      toaster.error('Experience must be defined.');
      return;
    }

    if (!user?.id) {
      toaster.error('User must be defined.');
      return;
    }

    addToCart({
      type: 'donation',
      donor: { id: user.id },
      allocation: { type: 'fundraiser', id: fundraiser.id },
      message: values.message,
      makeAnonymous,
      price,
    });

    openCheckout({
      id: fundraiser.id,
      experience: { id: fundraiser.attendee.experience.id },
    });
    checkout(fundraiser.attendee.experience.id);
  };

  const validateForm = (values: DonationFormValues) => {
    return validateWithSchema(DONATION_FORM_SCHEMA, values);
  };

  return {
    fundraiser,
    fetching,

    validateForm,
    handleSubmit,
  };
}
