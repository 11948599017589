import React from 'react';
import { Grid, paperClasses, Theme, Typography, typographyClasses } from '@mui/material';
import { LocalCafeOutlined } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

import { Border, composeStyles, PaletteColor, Shade } from '@vizsla/theme';
import { getUserFullName } from '@vizsla/utils';
import { UserAvatar } from '@vizsla/components';

const useStyles = makeStyles((theme: Theme) => ({
  titleIcon: {
    textAlign: 'center',
  },
  title: {
    textAlign: 'center',
  },
  stats: {
    textAlign: 'center',
    [`&.${typographyClasses.root}`]: {
      color: Shade.Gray[400],
    },
  },
  leaderPaper: {
    border: composeStyles('1px', Border.Solid, Shade.Gray[100]),
    borderRadius: '50%',
    [`&.${paperClasses.root}`]: {
      borderRadius: '50%',
    },
    height: 220,
    width: 220,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  category: {
    [`&.${typographyClasses.root}`]: {
      color: PaletteColor.GrayText,
      textTransform: 'uppercase',
    },
  },
  achievement: {
    [`&.${typographyClasses.root}`]: {
      color: PaletteColor.DefaultText,
    },
  },
}));

const LEADERS = [
  {
    id: 'some1',
    firstName: 'Hannah',
    lastName: 'Townsend',
  },
  {
    id: 'some2',
    firstName: 'Amelia',
    lastName: 'Cummings',
  },
  {
    id: 'some3',
    firstName: 'Hannah',
    lastName: 'Townsend',
  },
] as any;
export const LeaderBoardSection: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h3" className={classes.titleIcon}>
          <LocalCafeOutlined />
        </Typography>
      </Grid>
      <Grid item xs={12} marginBottom={1}>
        <Typography variant="h3" className={classes.title}>
          Leaderboard
        </Typography>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        {LEADERS.map((user: any) => (
          <Grid item xs={12} sm={6} md={4} key={user.id} container justifyContent="center">
            <div className={classes.leaderPaper}>
              <Grid marginBottom={1}>
                <UserAvatar user={user} />
              </Grid>
              <Typography variant="subtitle2">{getUserFullName(user)}</Typography>
              <Typography variant="body2" className={classes.achievement}>
                $5,435 raised
              </Typography>
            </div>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
