import styled from '@emotion/styled';
import { Tabs, Typography } from '@mui/material';

import { Button as ButtonBase } from '@vizsla/components';

/// StepTitle

export const StepTitle = styled(Typography)`
  text-align: center;
  font-weight: lighter;
`;

StepTitle.defaultProps = {
  variant: 'h5',
};

/// FormContainer

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(3)};
`;

/// AttendeesTabContainer

export const AttendeesTabContainer = styled.div`
  display: flex;
  max-width: 42rem;
  flex-direction: row;
  justify-content: center;
`;

/// AttendesTab

export const AttendesTab = styled(Tabs)`
  & .MuiTabs-indicator {
    display: none;
  }
`;

AttendesTab.defaultProps = {
  variant: 'scrollable',
  scrollButtons: 'auto',
};

/// Buttons

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: ${p => p.theme.spacing(1)};
`;

/// Button

export const Button = styled(ButtonBase)`
  margin: 0;
`;

Button.defaultProps = {
  variant: 'contained',
  type: 'submit',
};
