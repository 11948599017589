import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button, buttonClasses, ButtonProps, Theme } from '@mui/material';

interface StylesParams {
  themeColor: string;
  contained?: boolean;
}

const useStyles = makeStyles<Theme, StylesParams>(theme => ({
  landingButton: {
    [`&.${buttonClasses.root}`]: {
      height: '48px',
      width: '166px',
      borderRadius: '24px',
      border: ({ themeColor }) => `1px solid ${themeColor}`,
      background: ({ contained, themeColor }) => (contained ? themeColor : '#FFFFFF'),
      color: ({ contained, themeColor }) => (contained ? '#FFFFFF' : themeColor),
      '&:hover': {
        background: ({ contained, themeColor }) => (contained ? themeColor : '#FFFFFF'),
        filter: 'brightness(110%)',
      },
    },
  },
}));

interface LandingButtonProps extends ButtonProps {
  themeColor: string;
  contained?: boolean;
}

export const LandingButton: React.FC<LandingButtonProps> = ({
  themeColor,
  contained = false,
  children,
  ...props
}) => {
  const classes = useStyles({ themeColor, contained });

  return (
    <Button className={classes.landingButton} {...props}>
      {children}
    </Button>
  );
};
