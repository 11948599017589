import React, { useMemo } from 'react';
import { CircularProgress } from '@mui/material';

import { Button, DonationForm, DonationFormValues, Form, ShoppingCart } from '@vizsla/components';
import { DonationFrecuency } from '@vizsla/types';

import { SupportPageLayout } from 'src/layouts';
import { PurchaseDescriptorHeader } from 'src/components';

import { Buttons, Content, LoadingContent } from './FundraiserDonation.style';
import { useTeamFundraisingDonate } from './useFundraiserDonate';

const FORM_INITIAL_VALUES: DonationFormValues = {
  frecuency: DonationFrecuency.ONE_TIME,
};

export function FundraiserDonation() {
  const { fundraiser, fetching: loading, validateForm, handleSubmit } = useTeamFundraisingDonate();
  const experienceId = useMemo(() => {
    return fundraiser?.attendee?.experience?.id ?? undefined;
  }, [fundraiser]);
  if (loading || !fundraiser) {
    return (
      <SupportPageLayout>
        <SupportPageLayout.Content>
          <LoadingContent>
            <CircularProgress size="5rem" />
          </LoadingContent>
        </SupportPageLayout.Content>

        <SupportPageLayout.Sidebar>
          <ShoppingCart />
        </SupportPageLayout.Sidebar>
      </SupportPageLayout>
    );
  }

  return (
    <SupportPageLayout>
      <SupportPageLayout.Content>
        <PurchaseDescriptorHeader
          subtitle="You are donating to"
          title={fundraiser?.title ?? undefined}
        />

        <Form initialValues={FORM_INITIAL_VALUES} validate={validateForm} onSubmit={handleSubmit}>
          {form => (
            <Content>
              <DonationForm experienceId={experienceId} />

              <Buttons>
                <Button
                  loading={loading}
                  onClick={form.handleSubmit}
                  disabled={loading || form.invalid}
                >
                  Continue
                </Button>
              </Buttons>
            </Content>
          )}
        </Form>
      </SupportPageLayout.Content>

      <SupportPageLayout.Sidebar>
        <ShoppingCart />
      </SupportPageLayout.Sidebar>
    </SupportPageLayout>
  );
}
