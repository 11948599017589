import React from 'react';
import { Form } from 'react-final-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Grid, InputAdornment, Typography } from '@mui/material';
import { MailOutlined, LockOutlined } from '@mui/icons-material';

import { useModal, useAppAuth } from '@vizsla/hooks';
import { PaletteColor } from '@vizsla/theme';
import { Field, Modal, TextField } from '@vizsla/components';

import { MODALS } from 'src/constants/modals';
import { AuthSubmitButton } from 'src/components/auth';

const useStyles = makeStyles(() => ({
  loginForm: {
    width: 350,
  },
  signupText: {
    color: 'white',
  },
  textContainer: {
    margin: 'auto !important',
  },
}));

interface LoginModalProps {
  firstRoute: string | null;
}

const LoginModal: React.FC<LoginModalProps> = ({ firstRoute }) => {
  const classes = useStyles();

  const { isAuthenticated } = useAppAuth();
  const { isOpen, closeModal, openModal } = useModal(MODALS.LOGIN_MODAL);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { login } = useAppAuth();

  const onCreateAccount = (reset: (initialValues?: Partial<any> | undefined) => void) => {
    reset();
    closeModal();
    openModal(MODALS.SIGNUP_MODAL);
  };

  const onSubmit = React.useCallback(
    async data => {
      try {
        await login(data);

        closeModal();

        return navigate(pathname);
      } catch (e) {
        return {
          email: 'Incorrect email or password',
          password: 'Incorrect email or password',
        };
      }
    },
    [closeModal, login, navigate, pathname],
  );

  const onClose = () => {
    if (!isAuthenticated && firstRoute) {
      navigate(firstRoute);
    }
    closeModal();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Title>Login to Your Account</Modal.Title>
      <Modal.Content>
        <Form onSubmit={onSubmit}>
          {({ handleSubmit, submitting, pristine, hasValidationErrors, form }) => {
            const isDisabled = submitting || pristine || hasValidationErrors;

            return (
              <form onSubmit={handleSubmit} className={classes.loginForm}>
                <Grid container direction="column" spacing={3}>
                  <Grid item xs={12} paddingLeft={0}>
                    <Grid
                      container
                      spacing={0}
                      margin={0}
                      xs={12}
                      justifyContent="center"
                      direction="column"
                    >
                      <Grid item marginTop={2} marginBottom={2}>
                        <Typography variant="body1">Login with Email</Typography>
                      </Grid>
                      <Grid item paddingTop={1}>
                        <Field
                          name="email"
                          label="Email Address"
                          component={TextField}
                          e2e-id="email"
                          InputProps={{
                            placeholder: 'Email Address',
                            startAdornment: (
                              <InputAdornment position="start">
                                <MailOutlined />
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item paddingTop={2}>
                        <Field
                          name="password"
                          label="Password"
                          component={TextField}
                          type="password"
                          e2e-id="password"
                          InputProps={{
                            placeholder: 'Password',
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockOutlined />
                              </InputAdornment>
                            ),
                          }}
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} paddingLeft={0}>
                    <AuthSubmitButton
                      type="submit"
                      loading={submitting}
                      disabled={isDisabled}
                      e2e-id="submit"
                    >
                      Login
                    </AuthSubmitButton>
                  </Grid>
                  <Grid item xs={12} className={classes.textContainer}>
                    <Typography className={classes.signupText} component="span">
                      Don&apos;t have an Account?&nbsp;
                    </Typography>
                    <Typography
                      style={{ color: PaletteColor.TealText, cursor: 'pointer' }}
                      component="span"
                      onClick={() => onCreateAccount(form.reset)}
                    >
                      Create Account
                    </Typography>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default LoginModal;
