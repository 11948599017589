import React from 'react';

import { CartItem, ShoppingCartRegistrationData } from '@vizsla/types';

import { EXPERIENCE_ATTENDEE_REGISTRATION_SCHEMA } from 'src/constants';

import { AccountIcon, AlertIcon, CheckIcon, Container } from './ExperienceAttendeeTab.styles';

interface Props {
  index: number;
  active: boolean;
  reference: CartItem<ShoppingCartRegistrationData>;

  onClick?(): void;
}

export function ExperienceAttendeeTab(props: Props) {
  const label = React.useMemo(() => `Attendee ${props.index + 1}`, [props.index]);

  const completed = React.useMemo(() => {
    const value = props.reference.attendee ?? {};

    return EXPERIENCE_ATTENDEE_REGISTRATION_SCHEMA.isValidSync(value);
  }, [props.reference]);

  return (
    <Container
      label={label}
      value={props.index}
      active={props.active}
      onClick={props.onClick}
      disableRipple
      icon={
        <React.Fragment>
          <AccountIcon />

          {completed ? <CheckIcon /> : <AlertIcon />}
        </React.Fragment>
      }
    />
  );
}
