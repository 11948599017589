import styled from '@emotion/styled';
import { Typography } from '@mui/material';

import { PaletteColor } from '@vizsla/theme';
import { Button as ButtonBase } from '@vizsla/components';

/// StepTitle

export const StepTitle = styled(Typography)`
  text-align: center;
  font-weight: lighter;
`;

StepTitle.defaultProps = {
  variant: 'h5',
};

/// FormContainer

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(3)};
`;

/// OptionList

export const OptionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(2)};
`;

/// LegalText

export const LegalText = styled(Typography)`
  font-weight: lighter;
  text-align: justify;
  color: ${PaletteColor.GrayText};
`;

LegalText.defaultProps = {
  variant: 'caption',
};

/// Buttons

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: ${p => p.theme.spacing(1)};
`;

/// Button

export const Button = styled(ButtonBase)`
  margin: 0;
`;

Button.defaultProps = {
  variant: 'contained',
  type: 'submit',
};
