import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import {
  ExperienceAttendeesQuery,
  ExperienceAttendeesQueryVariables,
  EXPERIENCE_ATTENDEES_QUERY,
} from 'src/graphql/experience';

import { useSelectedExperience } from './selectedExperience';

export function useSelectedExperienceAttendees() {
  const { data: experience } = useSelectedExperience();

  const { data: response, loading } = useQuery<
    ExperienceAttendeesQuery,
    ExperienceAttendeesQueryVariables
  >(EXPERIENCE_ATTENDEES_QUERY, {
    skip: !experience?.id,
    variables: { id: experience?.id ?? '' },
  });

  const data = useMemo(() => response?.attendees, [response]);

  return {
    data,
    loading,
  };
}
