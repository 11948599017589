import React, { useMemo } from 'react';
import { Skeleton } from '@mui/material';

import { ExperienceLanding } from '@vizsla/components';
import { TemplateType } from '@vizsla/types';

import { useExperienceById, useExperienceId } from 'src/hooks/experience';
import { useLandingState } from 'src/hooks/landing';

export const ExperienceLandingPage: React.FC = () => {
  const id = useExperienceId();
  const { experience, loading } = useExperienceById(id);
  const { templates } = useLandingState();

  const experienceHeaderImages = useMemo(() => {
    const image = experience?.experienceHeaderImage;
    return image?.fileId ? [image] : [];
  }, [experience]);

  const experienceLandingTemplate = templates?.find(
    template => template?.templateType?.type === TemplateType.EXPERIENCE,
  );
  const experienceLandingStructure = experienceLandingTemplate?.structure?.appSchema;

  const isLoading = loading || templates.length === 0;

  if (isLoading) {
    return (
      <div>
        <Skeleton height={500} />
        <Skeleton height={100} />
        <Skeleton height={100} />
        <Skeleton height={100} />
      </div>
    );
  }

  return (
    <ExperienceLanding
      experience={experience}
      loading={loading}
      experienceLandingState={experienceLandingStructure}
      headerImages={experienceHeaderImages}
    />
  );
};
