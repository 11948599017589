import React from 'react';
import { Alert } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { ShoppingCart } from '@vizsla/components';
import { PaymentProvider } from '@vizsla/types';
import { useNotification, usePaymentGateway, useShoppingCart } from '@vizsla/hooks';
import { buildUrl } from '@vizsla/utils';

import { SupportPageLayout } from 'src/layouts';
import { useCheckoutPage } from 'src/hooks';
import { ROUTES } from 'src/constants';

import { Button, Buttons, Container, Header, HeaderTitle } from './CheckoutPage.styles';

export function CheckoutPage() {
  const [loading, setLoading] = React.useState(false);

  const { redirects, experience } = useCheckoutPage();

  const { checkout } = useShoppingCart();
  const { initialize } = usePaymentGateway();

  const toaster = useNotification();
  const navigate = useNavigate();

  const openPayment = () => {
    navigate(ROUTES.PAYMENT);
  };

  const openThanks = () => {
    if (!redirects?.success) return;
    navigate(redirects.success);
  };

  const handleNext = async () => {
    if (!experience?.id) return;

    setLoading(true);

    const response = await checkout({
      experience: { id: experience.id },
    });

    if (!response) {
      toaster.error("There's no any response from the application. Please try again.");
      return;
    }

    if (!response.needPayment) {
      openThanks();
      setLoading(false);
      return;
    }

    if (response.paymentIntent) {
      const provider = response.paymentIntent.provider as PaymentProvider;
      const metadata = response.paymentIntent.metadata ?? {};

      initialize(provider, metadata);
      openPayment();
    }
  };

  const handleStartOver = () => {
    if (redirects?.startOver) {
      navigate(redirects.startOver);
    }
  };

  const disabled = React.useMemo(() => loading || !experience, [loading, experience]);

  return (
    <SupportPageLayout>
      <SupportPageLayout.Content spacing={4}>
        <Container>
          <Header>
            <HeaderTitle>Billing Information</HeaderTitle>
          </Header>

          {/** @adwher https://8base-dev.atlassian.net/browse/VSL-270?focusedCommentId=29334 */}

          <Alert title="Hello" severity="warning">
            Lorem ipsum dolor sit amet.
          </Alert>
        </Container>

        <Buttons>
          <Button
            loading={loading}
            disabled={disabled}
            variant="outlined"
            onClick={handleStartOver}
          >
            Start Over
          </Button>

          <Button loading={loading} disabled={disabled} onClick={handleNext}>
            Continue
          </Button>
        </Buttons>
      </SupportPageLayout.Content>

      <SupportPageLayout.Sidebar>
        <ShoppingCart />
      </SupportPageLayout.Sidebar>
    </SupportPageLayout>
  );
}
