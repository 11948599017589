import React from 'react';
import { Skeleton } from '@mui/material';

import { Container, SubTitle, Title } from './PurchaseDescriptorHeader.style';

interface Props {
  subtitle: string;
  title?: string;
}

export function PurchaseDescriptorHeader(props: Props) {
  if (props.title) {
    return (
      <Container>
        <SubTitle>{props.subtitle}</SubTitle>
        <Title>{props.title}</Title>
      </Container>
    );
  }

  return (
    <Container>
      <Skeleton variant="text" width={250} height={46} />
      <Skeleton variant="text" width={350} height={64} />
    </Container>
  );
}
