import React from 'react';
import * as yup from 'yup';

import { Form, FORM_ARRAY_MUTATOR } from '@vizsla/components';
import { CartItem, ShoppingCartRegistrationData } from '@vizsla/types';
import { validateWithSchema } from '@vizsla/utils';
import { useCurrentUser, useShoppingCart } from '@vizsla/hooks';

import { ExperiencePurchaseLayout } from 'src/layouts';
import { useOpenExperiencePages, useSelectedExperience } from 'src/hooks';
import { EXPERIENCE_ATTENDEE_REGISTRATION_SCHEMA } from 'src/constants';

import {
  AttendeesTabContainer,
  AttendesTab,
  Button,
  Buttons,
  FormContainer,
  StepTitle,
} from './ExperienceAttendeesStep.style';
import { ExperienceAttendeeForm, ExperienceAttendeeFormValues } from './ExperienceAttendeeForm';
import { ExperienceWaiverForm, ExperienceWaiverFormValues } from './ExperienceWaiverForm';
import { ExperienceAttendeeTab } from './ExperienceAttendeeTab';

interface FormValues extends ExperienceWaiverFormValues {
  attendees: ExperienceAttendeeFormValues[];
}

const INITIAL: FormValues = {
  waiverAcceptance: false,
  waiverSignature: '',

  attendees: [],
};

export function ExperienceAttendeesStep() {
  const { fullName } = useCurrentUser();
  const { data: experience } = useSelectedExperience();
  const { items: cart } = useShoppingCart();
  const { openSwagBagsForm, openRegistrationForm: openOptionsSelector } = useOpenExperiencePages();

  const [active, setActive] = React.useState(0);

  const registrations = React.useMemo(() => {
    return cart.filter(i => i.type === 'registration') as CartItem<ShoppingCartRegistrationData>[];
  }, [cart]);

  React.useEffect(() => {
    if (registrations.length === 0) {
      openOptionsSelector();
    }
  }, [registrations]);

  const schema = React.useMemo(() => {
    let waiverAcceptance = yup.bool();
    let waiverSignature = yup.string();

    if (experience?.waiverEnabled) {
      waiverAcceptance = waiverAcceptance.required('Must accept the waiver');
      waiverSignature = waiverSignature.required('Must sign the waiver with your name');
    }

    return yup.object({
      waiverAcceptance,
      waiverSignature,

      attendees: yup.array(EXPERIENCE_ATTENDEE_REGISTRATION_SCHEMA),
    });
  }, [experience]);

  const validateForm = async (values: FormValues) => {
    const attendees = values.attendees ?? [];
    const errors = await validateWithSchema(schema, values);

    if (attendees.length < 1 || attendees.length !== registrations.length) {
      return {
        status: 'NO_FILLED',
        ...errors,
      };
    }

    return errors;
  };

  const handleSubmit = () => {
    openSwagBagsForm();
  };

  const selectAttendee = (index: number) => {
    setActive(index);
  };

  const handleStartOver = () => {
    openOptionsSelector();
  };

  return (
    <ExperiencePurchaseLayout>
      <StepTitle>
        Sweet, {fullName}. You&apos;re about to be in for the {experience?.name}!
      </StepTitle>

      <Form
        initialValues={INITIAL}
        mutators={{ ...FORM_ARRAY_MUTATOR }}
        validate={validateForm}
        onSubmit={handleSubmit}
      >
        {form => (
          <FormContainer>
            {registrations.length > 1 && (
              <AttendeesTabContainer>
                <AttendesTab value={active}>
                  {registrations.map((item, index) => (
                    <ExperienceAttendeeTab
                      key={item.id}
                      index={index}
                      active={active === index}
                      reference={item}
                      onClick={() => selectAttendee(index)}
                    />
                  ))}
                </AttendesTab>
              </AttendeesTabContainer>
            )}

            {registrations.map(
              (reference, index) =>
                active === index && (
                  <ExperienceAttendeeForm key={reference.id} reference={reference} index={index} />
                ),
            )}

            {experience?.waiverEnabled && experience?.waiverTextBody && <ExperienceWaiverForm />}

            <Buttons>
              <Button variant="outlined" onClick={handleStartOver}>
                Start Over
              </Button>

              <Button disabled={!form.valid} onClick={form.handleSubmit}>
                Continue
              </Button>
            </Buttons>
          </FormContainer>
        )}
      </Form>
    </ExperiencePurchaseLayout>
  );
}
