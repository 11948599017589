import React from 'react';
import { Grid, Theme, Typography, typographyClasses } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { PaletteColor } from '@vizsla/theme';
import { CampaignTeamMember } from '@vizsla/graphql';
import { formatDate } from '@vizsla/utils';
import { DateFormatPatterns } from '@vizsla/constants';
import { TableColumn, TableData, UserAvatarFullNameInline } from '@vizsla/components';

const useStyles = makeStyles((theme: Theme) => ({
  subTitle: {
    [`&.${typographyClasses.root}`]: {
      color: PaletteColor.GrayText,
    },
  },
  tableRowAction: {
    [`&.${typographyClasses.root}`]: {
      color: PaletteColor.DefaultText,
    },
  },
}));

interface MembersTableProps {
  members: CampaignTeamMember[] | null | undefined;
  styles?: any;
  loading?: boolean;
}

export const MembersTable: React.FC<MembersTableProps> = ({ styles, members, loading = false }) => {
  const classes = useStyles();
  const { pageBackgroundSecondColor, accentColor } = styles;

  const columns: Array<TableColumn<CampaignTeamMember>> = [
    {
      key: 'user',
      title: 'user',
      render: (member: CampaignTeamMember) => {
        return (
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            sx={{
              backgroundColor: pageBackgroundSecondColor || '#fff',
              borderColor: accentColor || '#000',
            }}
          >
            <Grid item>
              <UserAvatarFullNameInline
                user={member.attendee?.user}
                additionalCaption={
                  <Typography variant="caption">
                    {formatDate(member.attendee?.createdAt, DateFormatPatterns.shortMonthDayYear)}
                  </Typography>
                }
              />
            </Grid>
            <Grid item>
              <Typography variant="caption" className={classes.tableRowAction}>
                {member.attendee?.registrationOption?.name}
              </Typography>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <Grid>
      <Grid>
        <Typography variant="caption" className={classes.subTitle}>
          Team Members
        </Typography>
      </Grid>
      <TableData hideTitle dataSource={members ?? []} columns={columns} loading={loading} />
    </Grid>
  );
};
