import React from 'react';
import { Grid, Theme, Typography, typographyClasses } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { PaletteColor } from '@vizsla/theme';
import { TableData, UserAvatarFullNameInline } from '@vizsla/components';

const useStyles = makeStyles((theme: Theme) => ({
  subTitle: {
    [`&.${typographyClasses.root}`]: {
      color: PaletteColor.GrayText,
    },
  },
  tableRowAction: {
    [`&.${typographyClasses.root}`]: {
      color: PaletteColor.DefaultText,
    },
  },
}));

const LAST_ACTIVITY_DATA = [
  {
    id: '1',
    firstName: 'Eva',
    lastName: 'Flowers',
    activity: '25 Mile Bike Ride + $50 Donation',
  },
  {
    id: '2',
    firstName: 'Floyd',
    lastName: 'Marshall',
    activity: '25 Mile Bike Ride + $50 Donation',
  },
];

export const RecentActivityTable: React.FC = () => {
  const classes = useStyles();
  const columns = [
    {
      key: 'user',
      title: 'user',
      render: (user: any) => {
        return (
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <UserAvatarFullNameInline
                user={user}
                additionalCaption={<Typography variant="caption">Just registered</Typography>}
              />
            </Grid>
            <Grid item>
              <Typography variant="caption" className={classes.tableRowAction}>
                25 Mile Bike Ride + $50 Donation
              </Typography>
            </Grid>
          </Grid>
        );
      },
    },
  ];

  return (
    <Grid>
      <Grid>
        <Typography variant="caption" className={classes.subTitle}>
          35 new activities in the last 7 days
        </Typography>
      </Grid>
      <TableData hideTitle dataSource={LAST_ACTIVITY_DATA} columns={columns} />
    </Grid>
  );
};
