import { calcPercentage } from '@vizsla/utils';
import { CampaignTeamMember, useTeamFundraisingLandingQuery } from '@vizsla/graphql';

export const useTeamLandingData = (id: string) => {
  const { data: teamLandingData, loading } = useTeamFundraisingLandingQuery({
    variables: {
      filter: {
        OR: [
          {
            id: {
              contains: id,
            },
          },
          {
            webLink: {
              contains: id,
            },
          },
        ],
      },
    },
  });

  const team = teamLandingData?.teamFundraisingsList.items[0];
  const teamPercentage = calcPercentage(team?.totalRaised || 0, team?.fundraiserGoal || 0);
  const experience = teamLandingData?.teamFundraisingsList?.items[0].experience?.id;

  return {
    team,
    teamPercentage,
    loading,
    experience,
    teamCaptain: team?.teamCaptain?.items?.[0]?.attendee,
    campaign: team?.campaign,
    members: (team?.members?.items ?? []) as CampaignTeamMember[],
  };
};
