import React from 'react';
import styled from '@emotion/styled';
import { Typography, Button } from '@mui/material';
import PersonOffIcon from '@mui/icons-material/PersonOff';

import { useModal } from '@vizsla/hooks';

import { MODALS } from 'src/constants/modals';

import { EarlyBird } from '../../../modals/EarlyBird/EarlyBird';

export const Container = styled(Typography)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 800px;
`;
export const NotAvaialbe = (setAccess, experienceId) => {
  const { openModal } = useModal();
  const onClick = () => {
    openModal(MODALS.EARLY_BIRD, {
      setAccess,
      experienceId,
    });
  };
  return (
    <Container>
      <PersonOffIcon style={{ fontSize: 90 }} />
      <Typography display="flex" variant="h2">
        Not Available Experince
      </Typography>
      <Button onClick={onClick}> Early Bird</Button>
      <EarlyBird />
    </Container>
  );
};
