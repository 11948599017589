import styled from '@emotion/styled';

import { BorderRadius, Shadow } from '@vizsla/theme';

/// Container

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: support-sidebar;
  border-radius: ${BorderRadius.XL};
  box-shadow: ${Shadow.Elevation2};
  background-color: white;
  box-sizing: border-box;
  top: ${p => p.theme.spacing(4)};

  @media (min-width: 1170px) {
    position: sticky;
    flex: 0.4;
  }
`;
