import { bool, object, string, date } from 'yup';

export const EXPERIENCE_ATTENDEE_REGISTRATION_SCHEMA = object({
  firstName: string().required('Must have a first name'),
  lastName: string().required('Must have a last name'),
  email: string().email('Must be a valid email').required('Must have a valid email'),
  birthday: string()
    .nullable()
    .test('birthday', 'You must be at least 13 years old.', value => {
      const currentDate = new Date();
      let birthday = new Date();
      if (String(new Date(value ?? new Date())) !== 'Invalid Date') {
        birthday = new Date(value ?? '');
      }
      let age = currentDate.getFullYear() - birthday.getFullYear();
      birthday.setFullYear(currentDate.getFullYear());
      if (currentDate < birthday) {
        age -= 1;
      }
      return age >= 13;
    }),
  remiders: bool(),
  address: string().required('Must have a valid address'),
  city: string().required('Must have a valid city'),
  state: string().required('Must have a valid state'),
  zip: string().length(5, 'Must be just 5 digits').required('Must have a valid zip code'),
});
