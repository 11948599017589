import styled from '@emotion/styled';

import { BorderRadius, Shadow } from '@vizsla/theme';

/// Container

interface ContainerProps {
  spacing?: number;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(p.spacing || 0)};
  padding: ${p => p.theme.spacing(6, 8)};
  border-radius: ${BorderRadius.XL};
  box-shadow: ${Shadow.Elevation2};
  background-color: white;
  box-sizing: border-box;

  @media (min-width: 1170px) {
    flex: 0.6;
  }
`;
