import { useMemo } from 'react';

import { useAttendeeFundraiserLandingQuery } from '@vizsla/graphql';
import { calcPercentage } from '@vizsla/utils';

export const useFundraiserLandingData = (id: string) => {
  const { data, loading } = useAttendeeFundraiserLandingQuery({
    variables: {
      filter: {
        OR: [
          {
            id: {
              equals: id,
            },
          },
          {
            fundraising: {
              webLink: {
                equals: id,
              },
            },
          },
        ],
      },
    },
  });
  const fundraiserPercentage = useMemo(() => {
    const totalRaised = data?.attendeesList?.items[0]?.fundraising?.raised ?? 0;
    const fundraiserGoal = data?.attendeesList?.items[0]?.fundraising?.goal ?? 0;

    return calcPercentage(totalRaised, fundraiserGoal);
  }, [data]);

  return {
    fundraiser: data?.attendeesList?.items[0]?.fundraising,
    fundraiserPercentage,
    loading,
    attendee: data?.attendeesList?.items[0],
    experience: data?.attendeesList?.items[0]?.experience,
    campaign: data?.attendeesList?.items[0]?.experience?.campaign,
    team: data?.attendeesList?.items[0]?.teamMembership?.team,
    user: data?.attendeesList?.items[0]?.user,
  };
};
