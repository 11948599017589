import React from 'react';
import { Grid, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { Counter, Form, TableColumn, TableData } from '@vizsla/components';
import { useGetAllFileListQuery, useGetAssetByExperiencesListQuery } from '@vizsla/graphql';
import { useShoppingCart } from '@vizsla/hooks';
import { formatMoney } from '@vizsla/utils';

import { ExperiencePurchaseLayout as ExperiencePurchaseLayout } from 'src/layouts';
import { useOpenExperiencePages } from 'src/hooks';

import { Button, Buttons, Content, StepTitle } from './ExperienceAssetsStep.style';
import { AssetNameCell } from './AssetNameCell';

export function ExperienceAssetsStep() {
  const { experienceId } = useParams();
  const { items, add: addItem, remove: removeItem } = useShoppingCart();
  const {
    openDonationForm,
    openRegistrationForm: openOptionsSelector,
    openSwagBagsForm,
  } = useOpenExperiencePages();

  const { data, loading: loadingAssets } = useGetAssetByExperiencesListQuery({
    variables: {
      filter: {
        experienceId: {
          equals: experienceId,
        },
      },
    },
  });

  const assets = React.useMemo(() => data?.getAssetByExperiencesList?.items ?? [], [data]);

  const pictureIds = React.useMemo(() => {
    return assets.map(asset => asset.picture as string);
  }, [assets]);

  const { data: assetsPictures, loading: loadAssetsPictures } = useGetAllFileListQuery({
    variables: { filter: { id: { in: pictureIds } } },
  });

  const loading = React.useMemo(
    () => loadingAssets || loadAssetsPictures,
    [loadingAssets, loadAssetsPictures],
  );

  const picturesData = React.useMemo(() => {
    return assetsPictures?.filesList?.items || [];
  }, [assetsPictures]);

  const finalData = React.useMemo(() => {
    return assets.map(asset => {
      const image = picturesData.find(
        itF => itF.id === asset.picture && asset.itemToSellWhenOutOfStock === null,
      );

      return {
        id: asset.id ?? '',
        inventoryId: asset.inventoryId ?? '',
        name: asset.name ?? 'Item',
        available: asset.available ?? 0,
        continueSalesWhenOutOfStock: asset.continueSalesWhenOutOfStock ?? false,
        uri: image?.downloadUrl ?? undefined,
        price: asset.price ?? 0,
      };
    });
  }, [assets, picturesData]);

  const assetCount = (optionId: string) => {
    const count = items.map(item => item.type === 'asset' && item.asset.id === optionId);
    const countTotal = count.filter(item => item === true).length;

    return countTotal;
  };

  const addAsset = (data: typeof finalData[number], price: number) => {
    addItem({
      type: 'asset',
      name: data.name,
      price,
      asset: {
        id: data.id,
        name: data.name,
        uri: data.uri,
        inventoryId: data?.inventoryId,
      },
    });
  };

  const removeAsset = (optionId: string) => {
    const [item] = items.filter(item => item.type === 'asset' && item.asset.id === optionId);

    if (item) {
      removeItem({
        id: item.id,
      });
    }
  };

  const columns: TableColumn<typeof finalData[number]>[] = [
    {
      key: 'name',
      title: 'Name',

      render(root, item) {
        return <AssetNameCell storeItem={item} />;
      },
    },

    {
      key: 'price',
      title: 'Price',

      render(root, item) {
        return formatMoney(item.price, 2);
      },
    },

    {
      key: 'counter',
      title: 'Counter',

      render: (root, item) => {
        const value = assetCount(item.id);

        if (item.available <= 0 && item.continueSalesWhenOutOfStock === false) {
          return (
            <Grid item xs={5}>
              <Typography textAlign="center">Sold out</Typography>
            </Grid>
          );
        }

        return (
          <Grid sx={{ display: 'flex', alignSelf: 'center' }} item xs={5}>
            <Counter
              value={value}
              max={item.continueSalesWhenOutOfStock ? 1000 : item.available}
              onIncrease={() => addAsset(item, item.price)}
              onDecrease={() => removeAsset(item?.id)}
            />
          </Grid>
        );
      },
    },
  ];

  const handleBack = () => {
    openSwagBagsForm(experienceId);
  };

  const handleStartOver = () => {
    openOptionsSelector(experienceId);
  };

  const handleSubmit = () => {
    openDonationForm(experienceId);
  };

  return (
    <ExperiencePurchaseLayout>
      <Form onSubmit={handleSubmit}>
        {form => (
          <Content>
            <StepTitle>Would you like to buy something?</StepTitle>

            <TableData loading={loading} dataSource={finalData} columns={columns} />

            <Buttons>
              <Button variant="outlined" onClick={handleStartOver}>
                Start Over
              </Button>

              <Button variant="outlined" onClick={handleBack}>
                Back
              </Button>

              <Button onClick={form.handleSubmit} disabled={form.invalid}>
                Continue
              </Button>
            </Buttons>
          </Content>
        )}
      </Form>
    </ExperiencePurchaseLayout>
  );
}
