import React from 'react';
import { Grid, Tab, tabClasses, Theme } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { makeStyles } from '@mui/styles';

import { CampaignTeamMember } from '@vizsla/graphql';

import { RecentActivityTable } from './RecentActivityTable';
import { MembersTable } from './MembersTable';

interface StylesParams {
  themeColor: string;
}

const useStyles = makeStyles<Theme, StylesParams>(theme => ({
  tabPanel: {
    padding: 0,
  },
  tabList: {
    [`& .${tabClasses.root}`]: {
      color: ({ themeColor }) => themeColor,
    },
  },
}));

const MAIN_COLOR = '#EC308C';

enum PeopleSectionTab {
  RecentActivity = 'Recent Activity',
  Attendees = 'Attendees',
}

interface PeopleSectionProps {
  members: CampaignTeamMember[];
  styles: any;
}

export const PeopleSection: React.FC<PeopleSectionProps> = ({ members, styles }) => {
  const classes = useStyles({ themeColor: MAIN_COLOR });

  const [currentTab, setCurrentTab] = React.useState<PeopleSectionTab>(
    PeopleSectionTab.RecentActivity,
  );

  const tabs = [PeopleSectionTab.RecentActivity, PeopleSectionTab.Attendees];

  const handleTabSwitch = (_: React.ChangeEvent<any>, newValue: PeopleSectionTab) =>
    setCurrentTab(newValue);

  return (
    <Grid paddingTop={3}>
      <TabContext value={currentTab}>
        <Grid container justifyContent="center" marginBottom={3}>
          <TabList onChange={handleTabSwitch} className={classes.tabList}>
            {tabs.map((tab: PeopleSectionTab) => (
              <Tab key={tab} label={tab} value={tab} />
            ))}
          </TabList>
        </Grid>
        <TabPanel value={PeopleSectionTab.RecentActivity} className={classes.tabPanel}>
          <RecentActivityTable />
        </TabPanel>
        <TabPanel value={PeopleSectionTab.Attendees} className={classes.tabPanel}>
          <MembersTable members={members} styles={styles} />
        </TabPanel>
      </TabContext>
    </Grid>
  );
};
