import styled from '@emotion/styled';
import { ReceiptOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';

import { BorderRadius, Shade, Shadow } from '@vizsla/theme';

const SPACING_UNIT = 2;

/// Container

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  box-shadow: ${Shadow.Elevation2};
  padding: ${p => p.theme.spacing(0, SPACING_UNIT * 1.5)};
  border-radius: ${BorderRadius.M};
`;

/// Content

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${p => p.theme.spacing(SPACING_UNIT, 0)};
  gap: ${p => p.theme.spacing(3)};
`;

/// Description

export const Description = styled.div`
  display: flex;
  flex-direction: column;
`;

/// OptionsDivider

export const OptionsDivider = styled.div`
  display: block;
  height: 100%;
  border-left: dashed 1px ${Shade.Gray[100]};
`;

/// Title

export const Title = styled(Typography)``;

Title.defaultProps = {
  variant: 'subtitle1',
};

/// MoreDetailsLink

export const MoreDetailsLink = styled(Typography)`
  color: ${p => p.theme.palette.primary.main};
  user-select: none;
  cursor: pointer;
`;

MoreDetailsLink.defaultProps = {
  variant: 'body2',
};

/// PriceText

export const PriceText = styled(Typography)`
  font-weight: ${p => p.theme.typography.fontWeightRegular};
`;

PriceText.defaultProps = {
  variant: 'subtitle1',
};

/// CounterContainer

export const CounterContainer = styled.div`
  max-width: 12rem;
  display: flex;
  flex-direction: row;
`;

/// Icon

export const Icon = styled(ReceiptOutlined)`
  color: ${p => p.theme.palette.primary.main};
`;
