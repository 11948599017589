import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { CardMedia, Grid, Typography, Theme, Box, CircularProgress, Link } from '@mui/material';
import { useLazyQuery } from '@apollo/client';

import { buildUrl, formatMoney, getUserFullName } from '@vizsla/utils';
import { HtmlRenderer } from '@vizsla/components';
import { useCurrentUser, useJoinTeamFundraising, useModal } from '@vizsla/hooks';
import { LandingRoutes, MODALS } from '@vizsla/constants';
import { USER_ATTENDEE_EXPERIENCE, AttendeeListResponse } from '@vizsla/graphql';

import { ParticipatingType } from 'src/constants/participating';
import { ConfirmationJoiningTeamDialog } from 'src/modals';
import { useTeamLandingData } from 'src/hooks/team';
import { ShareSocialMedia } from 'src/modals';
import {
  Header,
  GradientDivider,
  GradientLinearProgress,
  LandingButton,
  MainAvatar,
  ParticipatingCard,
} from 'src/components/shared';

import { GallerySection, LeaderBoardSection, PeopleSection } from './components';
import maraphon from './assets/maraphon.jpg';
import { TeamLeaderBoard } from '../TeamLeaderBoard/TeamLeaderBoard';

// capture team id from url

const TEMP_STYLES = {
  headerBackground: '#fff', // header background
  accentColor: '#EA4235', // buttons, top border below image
  textLinksColor: '#EA4235', // text links color
  pageBackground: '#fff',
  headerLogoAlign: 'left', // left | center
  heroImage: maraphon,
};

interface StylesParams {
  accentColor: string;
  pageBackground: string;
}

const useStyles = makeStyles<Theme, StylesParams>(theme => ({
  root: {
    height: '100%',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    color: '#34b9ff',
  },

  content: {
    gridArea: 'content',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  contentBox: {
    width: '100%',
    borderTop: ({ accentColor }) => `8px solid ${accentColor}`,
    background: ({ pageBackground }) => pageBackground,
    marginBottom: '25px',
    display: 'flex',
    justifyContent: 'center',
  },

  boldText: {
    fontWeight: 'bold',
  },
}));

interface UserAttendeeExperience {
  attendeesList: AttendeeListResponse;
}
export const TeamLanding: React.FC = () => {
  const styles = TEMP_STYLES;
  const classes = useStyles(styles);
  const navigate = useNavigate();

  const { teamId } = useParams();
  const { openModal, isOpen } = useModal(MODALS.JOINING_TEAM_MODAL);
  const { openModal: openModalShare } = useModal(MODALS.SHARE_SOCIAL_MEDIA);
  const { user, loading: loadingUser } = useCurrentUser();

  const { campaign, teamCaptain, team, teamPercentage, members, experience } = useTeamLandingData(
    teamId ?? '',
  );
  const openShareModal = () => {
    openModalShare(MODALS.SHARE_SOCIAL_MEDIA);
  };
  const [findAttendeeByFilter] = useLazyQuery<UserAttendeeExperience>(USER_ATTENDEE_EXPERIENCE);
  const [hasMembership, setHasMembership] = useState(false);

  useEffect(() => {
    membershipValidation();
  }, [user?.id, experience, isOpen]);

  const membershipValidation = async () => {
    if (loadingUser) return;

    if ((user && !user.id) || !experience) {
      setHasMembership(false);
      return;
    }

    const response = await findAttendeeByFilter({
      variables: {
        userId: user.id,
        experienceId: experience,
      },
    });

    setHasMembership(!!response?.data?.attendeesList?.items[0]?.teamMembership);
  };

  const openJoinTeamModal = () => {
    openModal(MODALS.JOINING_TEAM_MODAL, {
      team,
    });
  };

  const openDonate = () => {
    const donateURL = buildUrl(LandingRoutes.TEAM_DONATION, { teamId });
    navigate(donateURL);
  };

  if (!campaign || !team) {
    return (
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress style={{ width: '65px', height: '65px' }} color="secondary" />
      </Box>
    );
  }
  const STORY_TEXT = `<span style="color: #2A394A; font-family: Inter; font-size: 16px; line-height: 26px; letter-spacing: -0.08px;">${
    team?.teamMissionDescription || ''
  }</span>`;

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <div>
          <CardMedia
            component="img"
            height="450"
            image={team?.teamPhoto?.downloadUrl || undefined}
            alt="campaign image"
          />
          <div className={classes.contentBox}>
            <Grid container justifyContent="center" spacing={6} sx={{ maxWidth: '1440px' }}>
              <Grid
                marginTop="-60px"
                item
                xs={6}
                md={3}
                container
                flexDirection="column"
                alignItems="center"
              >
                <Grid item>
                  <MainAvatar avatarUrl={team?.avatar?.downloadUrl || ''} />
                </Grid>
                {teamCaptain && (
                  <ParticipatingCard
                    type={ParticipatingType.teamCamptain}
                    name={getUserFullName(teamCaptain?.user)}
                    fundraisingGoal={teamCaptain?.fundraiserGoal}
                    totalRaised={teamCaptain?.totalRaised}
                    img={teamCaptain?.user?.avatar?.downloadUrl || undefined}
                  />
                )}
                <ParticipatingCard
                  type={ParticipatingType.benefitingCharity}
                  name={campaign?.name}
                  fundraisingGoal={campaign?.fundraisingGoal}
                  totalRaised={campaign?.totalRaised}
                  img={campaign?.logo?.downloadUrl || undefined}
                />
              </Grid>
              <Grid marginTop={4} item xs={10} md={6} container flexDirection="column">
                <Grid container item spacing={3}>
                  <Grid container item xs={12} md={8} flexDirection="column">
                    <Grid item marginTop="10px">
                      <Typography variant="h1">{team.name}</Typography>
                    </Grid>
                    <Grid item container spacing={2}>
                      <Grid item>
                        <Link sx={{ color: `${styles.textLinksColor}` }}>
                          {team.members?.count} members
                        </Link>
                      </Grid>
                      <Grid item>
                        <Link sx={{ color: `${styles.textLinksColor}` }}>
                          Ranked 4 out of 20 teams
                        </Link>
                      </Grid>
                    </Grid>
                    <Grid item maxWidth="375px" width="100%" marginTop={2}>
                      <GradientLinearProgress
                        height="16px"
                        variant="determinate"
                        value={teamPercentage}
                      />
                    </Grid>
                    <Grid item marginTop={2}>
                      <Typography variant="body2" sx={{ fontSize: '21px', lineHeight: '20px' }}>
                        <span className={classes.boldText}>{`${formatMoney(
                          team?.totalRaised,
                        )} Raised `}</span>
                        {`of a goal of ${formatMoney(team?.fundraiserGoal)}`}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    xs={12}
                    md={4}
                    item
                    container
                    direction={['row', 'row', 'column']}
                    spacing={2}
                    marginTop={2}
                  >
                    <Grid item>
                      <LandingButton themeColor={styles.accentColor} contained onClick={openDonate}>
                        DONATE
                      </LandingButton>
                    </Grid>
                    <Grid item>
                      {!hasMembership && (
                        <LandingButton themeColor={styles.accentColor} onClick={openJoinTeamModal}>
                          JOIN TEAM
                        </LandingButton>
                      )}
                    </Grid>
                    <ConfirmationJoiningTeamDialog />
                    <Grid item>
                      <LandingButton themeColor={styles.accentColor} onClick={openShareModal}>
                        SHARE
                      </LandingButton>
                      <ShareSocialMedia />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid width="100%" item marginTop="50px">
                  <GradientDivider color="#e9e9e9" />
                </Grid>
                <Grid item marginTop="50px">
                  <Typography variant="h3" marginBottom={1}>
                    {team?.teamMissionTitle}
                  </Typography>
                  <HtmlRenderer html={STORY_TEXT} />
                </Grid>
                <Grid item marginTop="50px">
                  <GallerySection images={team?.gallery?.items} color={styles.accentColor} />
                </Grid>
                <Grid item marginTop="50px">
                  <GradientDivider color={styles.accentColor} />
                </Grid>
                <Grid item marginTop="50px">
                  <TeamLeaderBoard />
                </Grid>
                <Grid item marginTop="50px">
                  <PeopleSection members={members} styles={styles} />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </main>
    </div>
  );
};
