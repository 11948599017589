import React from 'react';
import { CardMedia, Grid, Paper, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const PAPER_BLOCK_SIZE = 215;
const IMAGE_SIZE = PAPER_BLOCK_SIZE - 16;

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(1),
    borderRadius: 0,
  },
  imageWrap: {
    width: `${PAPER_BLOCK_SIZE}px`,
  },
}));

interface GallerySectionPropos {
  color: string;
  images?: any[];
}

export const GallerySection: React.FC<GallerySectionPropos> = ({ color, images }) => {
  const classes = useStyles();

  if (images?.length === 0) {
    return null;
  }

  return (
    <Grid>
      <Typography variant="h2" textAlign="center" marginBottom={3}>
        Gallery
      </Typography>
      <Grid container spacing={3}>
        {images?.map((image: any) => (
          <Grid item container justifyContent="center" xs={12} sm={6} md={4} lg={3} key={image.url}>
            <Grid className={classes.imageWrap}>
              <Paper variant="outlined" className={classes.paper}>
                <CardMedia
                  component="img"
                  height={IMAGE_SIZE}
                  image={image.downloadUrl}
                  alt={image.alt}
                />
              </Paper>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
