import React from 'react';
import useRef from 'react';
import { Alert, Snackbar, TextField, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { Modal, SocialNetwork } from '@vizsla/components';
import { useModal } from '@vizsla/hooks';

import { MODALS } from 'src/constants/modals';

import {
  Container,
  Divider,
  Section,
  Title,
  Text,
  SocialContainer,
  SubTitle,
  Icon,
  CopyButton,
} from './ShareSocialMedia.style';

export function ShareSocialMedia() {
  const { isOpen, closeModal } = useModal(MODALS.SHARE_SOCIAL_MEDIA);
  const [open, setOpen] = React.useState(false);
  const currentURL = window.location.href;
  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const copied = async () => {
    const URL = await navigator.clipboard.writeText(window.location.toString());
    setOpen(true);
    return URL;
  };
  return (
    <Modal isOpen={isOpen} slideDirection="left" onClose={closeModal}>
      <Modal.Content>
        {isOpen ? (
          <Icon aria-label="close" onClick={() => closeModal()}>
            <CloseIcon />
          </Icon>
        ) : null}
        <Container>
          <Title>Share</Title>
          <Text>Pellentesque elit ullamcorper dignissim</Text>
          <Divider />
          <Section>
            <SocialContainer>
              <SocialNetwork type="facebook" link="https://facebook.com" brandingColor />
              <SocialNetwork type="twitter" link="https://twitter.com" brandingColor />
              <SocialNetwork type="mail" link="mailto:MAIL" brandingColor />
            </SocialContainer>
          </Section>
          <Divider />
        </Container>
        <SubTitle>Share using page URL</SubTitle>
        <Section>
          <TextField variant="filled" size="small" value={currentURL} disabled fullWidth />
          <CopyButton onClick={copied}>Copy</CopyButton>
          <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
              Copied to Clipboard!
            </Alert>
          </Snackbar>
        </Section>
      </Modal.Content>
    </Modal>
  );
}
