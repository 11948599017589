import React from 'react';

import { Container } from './SupportPageLayoutContent.style';

interface SupportPageLayoutContentProps {
  spacing?: number;
}

export function SupportPageLayoutContent(
  props: React.PropsWithChildren<SupportPageLayoutContentProps>,
) {
  return <Container spacing={props.spacing}>{props.children}</Container>;
}
