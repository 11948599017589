import React from 'react';
import { useNavigate } from 'react-router-dom';

import { PaymentGateway, ShoppingCart } from '@vizsla/components';
import { useNotification } from '@vizsla/hooks';

import { SupportPageLayout } from 'src/layouts';
import { useCheckoutPage } from 'src/hooks';

import { Container, Header, HeaderTitle } from './PaymentPage.styles';

export function PaymentPage() {
  const { redirects } = useCheckoutPage();

  const navigate = useNavigate();
  const notifications = useNotification();

  const handleSucceeded = () => {
    if (redirects?.success) {
      navigate(redirects.success);
    }
  };

  const handleRejected = () => {
    notifications.error(
      'Something goes wrong while processing your payment. Check your payment method information.',
    );
  };

  return (
    <SupportPageLayout>
      <SupportPageLayout.Content spacing={4}>
        <Container>
          <Header>
            <HeaderTitle>Payment Details</HeaderTitle>
          </Header>

          <PaymentGateway onSucceeded={handleSucceeded} onRejected={handleRejected} />
        </Container>
      </SupportPageLayout.Content>

      <SupportPageLayout.Sidebar>
        <ShoppingCart />
      </SupportPageLayout.Sidebar>
    </SupportPageLayout>
  );
}
