import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { gql, useQuery } from '@apollo/client';

import { RegistrationOptionFilter, RegistrationOption } from '@vizsla/graphql';

const REGISTRATION_OPTION_LIST_QUERY = gql`
  query RegistrationOptionList($filter: RegistrationOptionFilter) {
    options: registrationOptionsList(filter: $filter) {
      count
      items {
        id
        name
        description
        experience {
          id
          name
          startDate
          endDate
        }
        pricingSettings {
          pricingType
          pricingEnabled

          pricingTiers {
            items {
              id
              taxDeductibleType
              price
              startDate
              endDate
            }
          }
        }
      }
    }
  }
`;

interface RegistrationOptionListQuery {
  options: {
    count: number;
    items: RegistrationOption[];
  };
}

interface RegistrationOptionListQueryVariables {
  filter: RegistrationOptionFilter;
}

export function useAvailableRegistrationOptions(filter?: RegistrationOptionFilter) {
  const nowISO = useMemo(() => DateTime.now().toISO(), []);

  const filterByDate: RegistrationOptionFilter = {
    AND: [
      {
        OR: [
          { registrationStartDate: { is_empty: true } },
          { registrationStartDate: { lte: nowISO } },
        ],
      },
      {
        OR: [{ registrationEndDate: { is_empty: true } }, { registrationEndDate: { gte: nowISO } }],
      },
      {
        OR: [
          {
            pricingSettings: {
              pricingType: { equals: 'Scaled' },
              pricingTiers: {
                some: {
                  AND: [
                    {
                      OR: [{ startDate: { is_empty: true } }, { startDate: { lte: nowISO } }],
                    },
                    {
                      OR: [{ endDate: { is_empty: true } }, { endDate: { gte: nowISO } }],
                    },
                  ],
                },
              },
            },
          },
          {
            pricingSettings: {
              pricingType: { equals: 'Fixed' },
            },
          },
        ],
      },
    ],
  };

  const { data: response, loading } = useQuery<
    RegistrationOptionListQuery,
    RegistrationOptionListQueryVariables
  >(REGISTRATION_OPTION_LIST_QUERY, {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-only',
    variables: {
      filter: {
        AND: [filter ?? {}, filterByDate],
      },
    },
  });

  const data = useMemo(() => response?.options.items ?? [], [response]);

  return {
    data,
    loading,
  };
}
