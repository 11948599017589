import React from 'react';

import { formatMoney } from '@vizsla/utils';

import { useSelectedTeamTopFundraisersDonations } from 'src/hooks';

import { LeaderBoardCard } from '../LeaderBoardCard';

export function MostDonationsCard() {
  const { data, loading } = useSelectedTeamTopFundraisersDonations();

  const top = React.useMemo(() => data[0], [data]);
  const user = React.useMemo(() => top?.attendee?.user ?? undefined, [top]);

  const formattedDonations = React.useMemo(() => {
    const groups: any | undefined = top?.allocatedDonations?.groups?.[0];
    const total = Number(groups?.total ?? 0);

    return formatMoney(total);
  }, [top]);

  const description = React.useMemo(() => {
    return `${formattedDonations} raised`;
  }, [formattedDonations]);

  return (
    <LeaderBoardCard
      title="Top Fundraiser"
      loading={loading}
      description={description}
      user={user}
    />
  );
}
