import React from 'react';
import { Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  avatarWrapper: {
    backgroundColor: '#fff',
    padding: '10px',
    borderRadius: '50%',
    left: 0,
  },
});

type MainAvatarProps = {
  avatarUrl: string;
};

export const MainAvatar: React.FC<MainAvatarProps> = ({ avatarUrl }) => {
  const classes = useStyles();

  return (
    <div className={classes.avatarWrapper}>
      <Avatar
        src={avatarUrl}
        sx={{
          width: 177,
          height: 178,
        }}
        variant="circular"
      />
    </div>
  );
};
