import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { LANDING_ROUTES } from '@vizsla/constants';
import { useExperienceId } from '@vizsla/hooks';
import { buildUrl } from '@vizsla/utils';

import { useSelectedExperience } from './selectedExperience';

export function useOpenExperiencePages() {
  const navigate = useNavigate();

  const { data: experience } = useSelectedExperience();

  const routeId = useExperienceId();

  const selectedId = useMemo(() => experience?.id ?? undefined, [experience]);
  const fallbackId = useMemo(() => routeId ?? selectedId, [routeId, selectedId]);

  function open(route: string, params: Record<string, any>) {
    const destination = buildUrl(route, params);
    navigate(destination);
  }

  function openLanding(experienceId = fallbackId) {
    open(LANDING_ROUTES.EXPERIENCE, { experienceId });
  }

  function openRegistrationForm(experienceId = fallbackId) {
    open(LANDING_ROUTES.EXPERIENCE_REGISTRATION, { experienceId });
  }

  function openAttendeesForm(experienceId = fallbackId) {
    open(LANDING_ROUTES.EXPERIENCE_ATTENDEES, { experienceId });
  }

  function openSwagBagsForm(experienceId = fallbackId) {
    open(LANDING_ROUTES.EXPERIENCE_SWAGBAGS, { experienceId });
  }

  function openAssetsForm(experienceId = fallbackId) {
    open(LANDING_ROUTES.EXPERIENCE_ASSETS, { experienceId });
  }

  function openDonationForm(experienceId = fallbackId) {
    open(LANDING_ROUTES.EXPERIENCE_DONATION, { experienceId });
  }

  function openThanks(experienceId = fallbackId) {
    open(LANDING_ROUTES.EXPERIENCE_THANKS, { experienceId });
  }

  return {
    openLanding,
    openRegistrationForm,
    openAttendeesForm,
    openSwagBagsForm,
    openAssetsForm,
    openDonationForm,
    openThanks,
  };
}
