import React from 'react';

import { ThanksPage } from 'src/components';
import { useSelectedTeamFundraising } from 'src/hooks';

export function TeamFundraisingDonationThanks() {
  const { data: team } = useSelectedTeamFundraising();

  const name = React.useMemo(() => team?.name ?? 'A fundraising team', [team]);
  const image = React.useMemo(() => team?.avatar?.downloadUrl ?? undefined, [team]);

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return <ThanksPage title={name} subtitle="You had donated successfully to" uri={image} />;
}
