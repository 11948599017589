import React from 'react';
import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

interface StylesParams {
  color: string;
  height: string;
}

const useStyles = makeStyles<Theme, StylesParams>(theme => ({
  divider: {
    height: ({ height }) => height,
    background: ({ color }) => `linear-gradient(90deg, #FFFFFF 0%, ${color} 50%, #FFFFFF 100%)`,
  },
}));

interface GradientDividerProps {
  color: string;
  height?: string;
}

export const GradientDivider: React.FC<GradientDividerProps> = ({ color, height = '2px' }) => {
  const classes = useStyles({ color, height });

  return <div className={classes.divider} />;
};
