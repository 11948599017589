import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { ShoppingCart } from '@vizsla/components';

import {
  ExperienceRegistrationQuery,
  ExperienceRegistrationQueryVariables,
  EXPERIENCE_REGISTRATION_QUERY,
} from 'src/graphql/experience';
import { SupportPageLayout } from 'src/layouts';
import { PurchaseDescriptorHeader } from 'src/components';
import { useSelectedExperience } from 'src/hooks';

import { Container } from './ExperiencePurchaseLayout.styles';

export function ExperiencePurchaseLayout(props: React.PropsWithChildren<unknown>) {
  const { data: experience, setData, setLoading } = useSelectedExperience();
  const { experienceId } = useParams();

  const { data: response, loading: fetching } = useQuery<
    ExperienceRegistrationQuery,
    ExperienceRegistrationQueryVariables
  >(EXPERIENCE_REGISTRATION_QUERY, {
    skip: !experienceId,
    fetchPolicy: 'cache-first',
    variables: {
      id: experienceId ?? '',
    },
  });

  React.useEffect(() => {
    setLoading(fetching);
  }, [fetching]);

  React.useEffect(() => {
    if (response?.experience) {
      setData(response.experience);
    }
  }, [response]);

  return (
    <SupportPageLayout>
      <SupportPageLayout.Content>
        <Container>
          <PurchaseDescriptorHeader
            subtitle="You are registering for"
            title={experience?.name ?? undefined}
          />

          {props.children}
        </Container>
      </SupportPageLayout.Content>

      <SupportPageLayout.Sidebar>
        <ShoppingCart showDiscount={experience ? { experience } : undefined} />
      </SupportPageLayout.Sidebar>
    </SupportPageLayout>
  );
}
