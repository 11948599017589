import * as yup from 'yup';

import { DISCOUNT_NAME } from '@vizsla/constants';

/** @deprecated */
export const CampaignGetDiscountFormSchema = yup.object({
  discountCodeName: yup
    .string()
    .nullable()
    .matches(DISCOUNT_NAME, {
      message: 'Discount Code must contain both uppercase letters and numbers',
    })
    .max(15, 'Discount Code is maximum 15 characters in length')
    .required('Discount Code is required'),
});
