import React from 'react';
import { isEqual, sumBy } from 'lodash';

import {
  CheckboxField,
  DateField,
  FormSpy,
  Field,
  NumberField,
  TextField,
  useForm,
  useFormState,
  DebouncedField,
} from '@vizsla/components';
import { CartItem, ShoppingCartRegistrationData } from '@vizsla/types';
import { useShoppingCart } from '@vizsla/hooks';

import { useSelectedExperienceAttendees } from 'src/hooks';

import { FormContainer, FormControl, FormSection, FormTitle } from './ExperienceAttendeeForm.style';

interface Props {
  reference: CartItem<ShoppingCartRegistrationData>;
  index: number;
}

export interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  birthday: string;
  gender: string;
  reminders: boolean;
  address: string;
  city: string;
  state: string;
  zip: string;
}

const GROUPER = 'attendees';

export function ExperienceAttendeeForm(props: Props) {
  const { change } = useForm();
  const { values: form } = useFormState();
  const { data: attendees } = useSelectedExperienceAttendees();
  const { items: cart, edit: editReference } = useShoppingCart();

  const values: FormValues | undefined = React.useMemo(() => {
    return form?.[GROUPER]?.[props.index];
  }, [form, props.index]);

  const prefix = React.useMemo(() => {
    return `${GROUPER}[${props.index}]`;
  }, [props.index]);

  const field = React.useCallback((field: string) => `${prefix}.${field}`, [prefix]);

  React.useEffect(() => {
    if (props.reference && values && props.reference.id) {
      editReference({
        id: props.reference.id,

        attendee: {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phone: values.phone,
          birthday: values.birthday,
          reminders: values.reminders,
          gender: values.gender,
          address: values.address,
          state: values.state,
          city: values.city,
          zip: values.zip,
        },
      });
    }
  }, [values]);

  React.useEffect(() => {
    if (!isEqual(props.reference.attendee, values)) {
      change(prefix, props.reference.attendee);
    }
  }, [props.reference]);

  const validateEmail = (email: string) => {
    const sameRegistration = sumBy(cart, i =>
      Number(i.type === 'registration' && i.attendee?.email === email),
    );

    const sameExperience = sumBy(attendees?.items ?? [], a => Number(a.user?.email === email));

    if (sameRegistration > 1) {
      return 'This email is already used in another form';
    }

    if (sameExperience > 0) {
      return 'This email is already registered in this experience';
    }

    return undefined;
  };

  return (
    <FormSpy>
      {form => (
        <FormContainer>
          <FormTitle>Personal Information</FormTitle>

          <FormSection>
            <FormControl>
              <Field
                label="First Name"
                name={field('firstName')}
                component={TextField}
                required
                fullWidth
              />
            </FormControl>

            <FormControl>
              <Field
                label="Last Name"
                name={field('lastName')}
                component={TextField}
                required
                fullWidth
              />
            </FormControl>

            <FormControl>
              <DebouncedField
                label="Email"
                name={field('email')}
                validate={validateEmail}
                component={TextField}
                debounce={400}
                required
                fullWidth
              />
            </FormControl>

            <FormControl>
              <Field
                label="Phone Number"
                name={field('phone')}
                component={NumberField}
                formatMask="+1 (###) ###-####"
                required
                fullWidth
              />
            </FormControl>

            <FormControl>
              <Field
                label="Birthday"
                name={field('birthday')}
                component={DateField}
                required
                fullWidth
              />
            </FormControl>

            <FormControl>
              <Field label="Gender" name={field('gender')} component={TextField} fullWidth />
            </FormControl>

            <FormControl xs={12} md={12} lg={12}>
              <Field
                name={field('reminders')}
                label="Receive a text message for donations made on your behalf and important event details."
                component={CheckboxField}
              />
            </FormControl>
          </FormSection>

          <FormTitle>Address</FormTitle>

          <FormSection>
            <FormControl xs={12} lg={12}>
              <Field
                label="Address"
                name={field('address')}
                component={TextField}
                required
                fullWidth
              />
            </FormControl>

            <FormControl xs={6} md={6} lg={4}>
              <Field label="City" name={field('city')} component={TextField} required fullWidth />
            </FormControl>

            <FormControl xs={6} md={6} lg={4}>
              <Field label="State" name={field('state')} component={TextField} required fullWidth />
            </FormControl>

            <FormControl xs={6} md={6} lg={4}>
              <Field
                label="Zip Code"
                name={field('zip')}
                component={TextField}
                maxLength={5}
                minLength={5}
                required
                fullWidth
              />
            </FormControl>
          </FormSection>
        </FormContainer>
      )}
    </FormSpy>
  );
}
