import React from 'react';
import { Check as CheckIcon } from '@mui/icons-material';

import { SocialNetwork } from '@vizsla/components';

import {
  CheckContainer,
  Container,
  Divider,
  Section,
  SubTitle,
  ThanksText,
  Title,
  Text,
  SocialContainer,
  Logo,
} from './ThanksPage.styles';

interface Props {
  title: string;
  subtitle: string;

  uri?: string;
}

export function ThanksPage(props: Props) {
  return (
    <Container>
      {props.uri && <Logo src={props.uri} alt={props.title} />}

      <Section>
        <SubTitle>{props.subtitle}</SubTitle>
        <Title>{props.title}</Title>
      </Section>

      <CheckContainer>
        <CheckIcon fontSize="inherit" />
      </CheckContainer>

      <ThanksText>Thank You!</ThanksText>

      <Divider />

      <Section>
        <Title>Spread the Word</Title>
        <Text>Pellentesque elit ullamcorper dignissim</Text>

        <SocialContainer>
          <SocialNetwork type="facebook" link="https://facebook.com" brandingColor />
          <SocialNetwork type="twitter" link="https://twitter.com" brandingColor />
          <SocialNetwork type="instagram" link="https://instagram.com" brandingColor />
          <SocialNetwork type="mail" link="mailto:MAIL" brandingColor />
          <SocialNetwork type="message" link="https://wa.me" brandingColor />
        </SocialContainer>
      </Section>

      <Divider />

      <Section>
        <Title>Have Questions?</Title>
        <Text>If you have questions about your donation please contact us at:</Text>

        <Text>MAIL</Text>
      </Section>
    </Container>
  );
}
