import styled from '@emotion/styled';
import { Typography } from '@mui/material';

import { Shade } from '@vizsla/theme';

/// Container

export const Container = styled.div`
  display: flex;
  min-height: 70vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${p => p.theme.spacing(6)};
  padding: ${p => p.theme.spacing(6)};
`;

/// Section

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${p => p.theme.spacing(1)};
`;

/// Divider

export const Divider = styled.hr`
  max-width: 35rem;
  width: 80%;
  height: 1px;
  background-color: ${Shade.Gray[100]};
  border: none;
`;

/// Text

export const Text = styled(Typography)`
  color: ${Shade.Gray[500]};
`;

Text.defaultProps = {
  variant: 'body2',
};

/// Logo

export const Logo = styled.img`
  max-height: ${p => p.theme.spacing(8)};
`;

/// SubTitle

export const SubTitle = styled(Text)`
  letter-spacing: ${p => p.theme.spacing(0.3)};
  font-weight: ${p => p.theme.typography.fontWeightLight};
  text-transform: uppercase;
  text-align: center;
`;

SubTitle.defaultProps = {
  variant: 'subtitle2',
};

/// Title

export const Title = styled(Typography)`
  text-align: center;
`;

Title.defaultProps = {
  variant: 'h2',
};

/// ThanksText

export const ThanksText = styled(Typography)`
  color: ${p => p.theme.palette.success.main};
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
`;

ThanksText.defaultProps = {
  variant: 'display1',
};

/// CheckContainer

const CHECK_SIZE = 15;

export const CheckContainer = styled.div`
  width: ${p => p.theme.spacing(CHECK_SIZE)};
  height: ${p => p.theme.spacing(CHECK_SIZE)};
  border-radius: ${p => p.theme.spacing(CHECK_SIZE)};
  background-color: ${p => p.theme.palette.success.main};
  font-size: ${p => p.theme.spacing(CHECK_SIZE / 2.5)};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;

/// SocialContainer

export const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${p => p.theme.spacing(2)};
  margin-top: ${p => p.theme.spacing(4)};
`;
