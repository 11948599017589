import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Divider, Grid, Skeleton, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { amber } from '@mui/material/colors';

import { HtmlRenderer, Modal } from '@vizsla/components';
import { useModal } from '@vizsla/hooks';
import { CampaignFaq } from '@vizsla/graphql';
import { FontSize, FontWeight, PaletteColor } from '@vizsla/theme';
import { CampaignFaqAllocation } from '@vizsla/types';

import { MODALS } from 'src/constants/modals';
import { usePublicCampaignFAQs } from 'src/hooks/campaign';

const StyledTextGrid = styled(Grid)(() => ({
  fontSize: FontSize.Default,
  '& p': { margin: 0, whiteSpace: 'normal' },
}));

const StyledIconBox = styled(Box)(() => ({
  height: 24,
  width: 24,
  borderRadius: 16,
  textAlign: 'center',
}));

const StyledModalAction = styled(Modal.Action)(() => ({
  height: 56,
  borderRadius: 28,
  margin: '0 auto',
}));

interface CampaignFAQListModalProps {
  allocation: CampaignFaqAllocation;
}

export const CampaignFAQListModal: React.FC<CampaignFAQListModalProps> = ({ allocation }) => {
  const { isOpen, closeModal } = useModal(MODALS.CAMPAIGN_FAQ_LIST_MODAL);
  const { experienceId } = useParams();
  const { allocationCampaignFaqs, loading } = usePublicCampaignFAQs(allocation, experienceId || '');
  const renderFAQItem = (campaignFaq: any, index: number) => {
    return (
      <React.Fragment key={campaignFaq.id}>
        <Grid
          item
          sx={{
            marginBottom: 2,
            marginTop: 1,
            paddingLeft: 0,
          }}
        >
          <Grid container spacing={1} marginBottom={1} wrap="nowrap">
            <Grid item xs="auto">
              <StyledIconBox bgcolor={amber.A400}>
                <Typography fontWeight={FontWeight.Bold} color={PaletteColor.White}>
                  Q
                </Typography>
              </StyledIconBox>
            </Grid>
            {campaignFaq.question && (
              <StyledTextGrid item xs fontWeight={FontWeight.Bold}>
                <HtmlRenderer html={campaignFaq.question} />
              </StyledTextGrid>
            )}
          </Grid>
          <Grid container spacing={1} marginBottom={1} wrap="nowrap">
            <Grid item xs="auto">
              <StyledIconBox bgcolor={amber['100']}>
                <Typography fontWeight={FontWeight.Bold} color={amber.A400}>
                  A
                </Typography>
              </StyledIconBox>
            </Grid>
            {campaignFaq.answer && (
              <StyledTextGrid item xs>
                <HtmlRenderer html={campaignFaq.answer} />
              </StyledTextGrid>
            )}
          </Grid>
        </Grid>
        {index !== allocationCampaignFaqs.length - 1 && <Divider sx={{ marginLeft: 1 }} />}
      </React.Fragment>
    );
  };

  const renderFAQsList = () => {
    if (allocationCampaignFaqs.length === 0) {
      return (
        <Typography textAlign="center" margin={2}>
          No Questions
        </Typography>
      );
    }
    return allocationCampaignFaqs.map(renderFAQItem);
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <Modal.Title>
        <Typography fontWeight={FontWeight.Black} fontSize={FontSize.XXL} textAlign="center">
          FAQs
        </Typography>
      </Modal.Title>
      <Modal.Content>
        <Grid
          container
          direction="column"
          spacing={2}
          sx={{
            fontFamily: 'Inter, sans-serif',
            lineHeight: 1.5,
            marginLeft: 0,
            width: '100%',
            minWidth: 500,
          }}
        >
          {loading ? (
            <Skeleton
              variant="rectangular"
              sx={{ width: '100%', height: 50, margin: '10px auto' }}
            />
          ) : (
            renderFAQsList()
          )}
        </Grid>
      </Modal.Content>
      <StyledModalAction type="secondary" content="Close" onAction={closeModal} />
    </Modal>
  );
};
