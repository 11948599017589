import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import {
  TOP_DONORS_TEAM_FUNDRAISERS,
  IndividualFundraising,
  IndividualFundraisingListResponse,
} from '@vizsla/graphql';

import { useSelectedTeamFundraiser } from './selectedTeamFundraiser';

interface TopDonorsQuery {
  fundraisers: IndividualFundraisingListResponse;
}

interface TopDonorsQueryVariables {
  teamId: string;
}

export function useSelectedTeamTopFundraisersDonors() {
  const [data, setData] = useState<IndividualFundraising[]>([]);
  const [loading, setLoading] = useState(false);

  const [executeQuery] = useLazyQuery<TopDonorsQuery, TopDonorsQueryVariables>(
    TOP_DONORS_TEAM_FUNDRAISERS,
  );

  const { data: team } = useSelectedTeamFundraiser();

  async function refetch() {
    if (!team?.id) return;

    setLoading(true);

    const response = await executeQuery({
      variables: { teamId: team.id },
    });

    const data = response.data?.fundraisers?.items;
    const dataTeam = data?.slice();

    if (Array.isArray(dataTeam)) {
      const sorted = dataTeam.sort((first, second) => {
        const firstGroup: any = first.allocatedDonations?.groups?.at(0);
        const secondGroup: any = second.allocatedDonations?.groups?.at(0);

        const firstDonors = firstGroup?.donors ?? 0;
        const secondDonors = secondGroup?.donors ?? 0;

        return secondDonors - firstDonors;
      });

      setData(sorted);
      setLoading(false);
    }
  }

  useEffect(() => {
    refetch();
  }, [team]);

  return {
    data,
    loading,

    refetch,
  };
}
