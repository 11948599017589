import * as yup from 'yup';
import _ from 'lodash';

import { getNowDate, isDateGreaterThan, validateWithSchema, ValidationObject } from '@vizsla/utils';
import {
  CampaignQuestionDisplaySettings,
  CampaignQuestionInputType,
  CampaignQuestionFormat,
} from '@vizsla/types';
import { CreditCardValidationSchema } from '@vizsla/stripe';
import {
  BillingAddressValidationSchema,
  PhoneSchema,
  PhoneSchemaRequired,
} from '@vizsla/constants';

export const AttendeeRegistrationOptionsStepSchema = yup
  .object({
    registrations: yup.array().of(
      yup.object({
        id: yup.string(),
        count: yup.number().default(0).min(0, 'Options number cannot be negative'),
      }),
    ),
  })
  .test(
    'at-least-one-registration-must-be-added',
    'You must choose at least one registration option',
    values => (values?.registrations ?? []).some(v => v.count > 0),
  );

export const AttendeePersonalInfoFormSchema = yup.object({
  waiver: yup.object().shape({
    accepted: yup
      .boolean()
      .test(
        'required-if-waiver-enabled',
        'Waiver agreement must be accepted',
        (acceptWaiver: boolean | null | undefined, { options }): boolean => {
          const { context } = options;

          const { shouldDisplayWaiver } = context as any;

          return shouldDisplayWaiver ? Boolean(acceptWaiver) : true;
        },
      ),
    signature: yup
      .string()
      .required('Waiver signature is required')
      .test(
        'required-if-waiver-enabled',
        'Waiver signature is required',
        (waiverSignature: string | null | undefined, { options }): boolean => {
          const { context } = options;

          const { shouldDisplayWaiver } = context as any;

          return shouldDisplayWaiver ? Boolean(waiverSignature) : true;
        },
      ),
  }),
  attendees: yup
    .array()
    .of(
      yup.object().shape({
        firstName: yup.string().nullable().required('First Name is required'),
        lastName: yup.string().nullable().required('Last Name is required'),
        email: yup.string().nullable().email('Invalid Email').required('Email is required'),
        phone: PhoneSchemaRequired.fields.number,

        birthday: yup
          .string()
          .nullable()
          .test(
            'birthday-earlier-than-now',
            'Invalid date for birthday',
            value => isDateGreaterThan(getNowDate(), value) || !value,
          ),

        street1: yup.string().nullable().required('Address is required'),
        state: yup.string().nullable().required('State is required'),
        city: yup.string().nullable().required('City is required'),
        zip: yup
          .string()
          .nullable()
          .required('Zip code is required')
          .matches(/^[0-9]{5}$/, { message: 'zip is invalid' })
          .min(5, 'Must be exactly 5 digits')
          .max(5, 'Must be exactly 5 digits'),
        // .required('Waiver agreement must be accepted'),
      }),
    )
    .min(1),
});

const getYupSchemaByQuestionInputType = (
  type: CampaignQuestionInputType,
  format: CampaignQuestionFormat,
): yup.BaseSchema => {
  switch (type) {
    case CampaignQuestionInputType.text:
    case CampaignQuestionInputType.date: {
      switch (format) {
        case CampaignQuestionFormat.email: {
          return yup.string().nullable().email();
        }

        case CampaignQuestionFormat.number: {
          return yup.number().nullable();
        }

        case CampaignQuestionFormat.phone: {
          return PhoneSchema.fields.number;
        }
        default:
          return yup.string().nullable();
      }
    }

    case CampaignQuestionInputType.checkbox: {
      return yup.boolean().nullable();
    }

    case CampaignQuestionInputType.radio:
    case CampaignQuestionInputType.select: {
      const schema = yup.string().nullable();
      if (format === CampaignQuestionFormat.multipleChoice) {
        return yup.array(schema).nullable();
      }
      return schema;
    }

    default: {
      return yup.mixed().nullable();
    }
  }
};

const getQuestionYupSchema = ({
  name,
  type: questionInputType,
  format,
  required,
}: CampaignQuestionDisplaySettings) => {
  const yupSchema = getYupSchemaByQuestionInputType(questionInputType, format);

  return yup.object({
    [name]: required ? yupSchema.required('Required') : yupSchema,
  });
};

type QuestionValidator = (
  value: string | null | undefined,
) => Promise<ValidationObject | undefined>;

export const getCustomQuestionValidator = (
  settings: CampaignQuestionDisplaySettings,
): QuestionValidator => {
  const schema = getQuestionYupSchema(settings);

  return async value => {
    const error = await validateWithSchema(schema, { [settings.name]: value });
    return !_.isEmpty(error) ? error : undefined;
  };
};

export const AttendeeRegistrationCheckoutStepSchema = yup.object({
  creditCard: CreditCardValidationSchema,
  billingAddress: BillingAddressValidationSchema,
});
