import styled from '@emotion/styled';
import { Avatar, Typography } from '@mui/material';

import { PaletteColor } from '@vizsla/theme';

/// Card

interface CardProps {
  size: number;
}

export const Card = styled.div<CardProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  border-radius: ${p => p.size}px;
  border: 1px solid ${p => p.theme.palette.grey[300]};
  background-color: white;
  gap: ${p => p.theme.spacing(1)};
  box-sizing: border-box;
`;

/// AvatarContainer

const AVATAR_SIZE = 7;

export const AvatarContainer = styled.div`
  width: ${p => p.theme.spacing(AVATAR_SIZE)};
  height: ${p => p.theme.spacing(AVATAR_SIZE)};
`;

/// AvatarImage

export const AvatarImage = styled(Avatar)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

/// Text

export const Text = styled(Typography)`
  text-align: center;
`;

/// Title

export const Title = styled(Typography)`
  text-align: center;
  text-transform: uppercase;
  color: ${PaletteColor.GrayText};
`;

Title.defaultProps = {
  variant: 'caption',
};
