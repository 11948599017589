import styled from '@emotion/styled';
import { Grid, Typography } from '@mui/material';

import { HtmlRenderer } from '@vizsla/components';
import { BorderRadius, Shade } from '@vizsla/theme';

/// FormContainer

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(3)};
`;

/// FormSection

export const FormSection = styled(Grid)``;

FormSection.defaultProps = {
  container: true,
  spacing: 2,
};

/// FormTitle

export const FormTitle = styled(Typography)``;

FormTitle.defaultProps = {
  variant: 'h5',
};

/// FormControl

export const FormControl = styled(Grid)``;

FormControl.defaultProps = {
  item: true,
  xs: 12,
};

/// HTMLRender

const HTML_RENDER_HEIGHT_UNITS = 30;

export const HTMLRender = styled(HtmlRenderer)`
  min-height: ${p => p.theme.spacing(HTML_RENDER_HEIGHT_UNITS)};
  max-height: ${p => p.theme.spacing(HTML_RENDER_HEIGHT_UNITS)};
  padding: ${p => p.theme.spacing(0, 1)};
  border: solid 1px ${Shade.Gray[200]};
  border-radius: ${BorderRadius.S};
  overflow-y: auto;
`;
