import React from 'react';
import _ from 'lodash';

import { ProtectedRouteProps } from '@vizsla/components';
import { useModal, useAppAuth } from '@vizsla/hooks';

import { MODALS } from 'src/constants/modals';

type PublicPageProtectedRouteProps = Pick<
  ProtectedRouteProps,
  'layout' | 'component' | 'componentProps'
> & {
  isPrivate?: boolean;
};

type RenderContentProps = Omit<PublicPageProtectedRouteProps, 'isAuthenticated'>;

const renderContent = ({
  component: Component,
  layout: Layout,
  componentProps,
}: RenderContentProps) => {
  if (!_.isNil(Component)) {
    if (!_.isNil(Layout)) {
      return (
        <Layout>
          <Component {...componentProps} />
        </Layout>
      );
    }

    return <Component />;
  }

  return null;
};

export const PublicPageProtectedRoute: React.FC<PublicPageProtectedRouteProps> = ({
  layout,
  component,
  componentProps,
  isPrivate = false,
}) => {
  const { isAuthenticated } = useAppAuth();
  const { openModal, isOpen, closeModal } = useModal(MODALS.LOGIN_MODAL);

  if (isAuthenticated || !isPrivate) {
    return renderContent({ component, componentProps, layout, isPrivate });
  }

  if (!isAuthenticated && !isOpen) {
    openModal(MODALS.LOGIN_MODAL);
  }

  if (isAuthenticated && isOpen) {
    closeModal(MODALS.LOGIN_MODAL);
  }

  return null;
};
