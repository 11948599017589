import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useNotification, usePaymentGateway, useShoppingCart } from '@vizsla/hooks';
import { PaymentProvider } from '@vizsla/types';

import { ROUTES } from 'src/constants';

import { useCheckoutPage } from './useCheckoutPage';

export function useCheckout() {
  const { checkout: checkoutOrder } = useShoppingCart();
  const toaster = useNotification();
  const { redirects } = useCheckoutPage();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const { initialize } = usePaymentGateway();

  const openThanks = React.useCallback(() => {
    if (!redirects?.success) return;
    navigate(redirects.success);
  }, [navigate, redirects?.success]);

  const openPayment = React.useCallback(() => navigate(ROUTES.PAYMENT), [navigate]);

  const checkout = React.useCallback(
    async (experienceId?: string | null) => {
      if (!experienceId) return;

      setLoading(true);

      const response = await checkoutOrder({ experience: { id: experienceId } });

      if (!response) {
        toaster.error("There's no any response from the application. Please try again.");
        return;
      }

      if (!response.needPayment) {
        openThanks();
        setLoading(false);
        return;
      }

      if (response.paymentIntent) {
        const provider = response.paymentIntent.provider as PaymentProvider;
        const metadata = response.paymentIntent.metadata ?? {};

        initialize(provider, metadata);
        openPayment();
      }
    },
    [checkoutOrder, initialize, openPayment, openThanks, toaster],
  );

  return [checkout, { loading }] as const;
}
