import React from 'react';
import { DateTime } from 'luxon';

import {
  ShoppingCartRegistrationData,
  useCurrentUser,
  useModal,
  useShoppingCart,
} from '@vizsla/hooks';
import { CounterField, Field, useForm } from '@vizsla/components';
import { CartItem } from '@vizsla/types';
import { formatMoney } from '@vizsla/utils';
import { RegistrationOption } from '@vizsla/graphql';

import { MODALS } from 'src/constants/modals';

import {
  Container,
  Content,
  CounterContainer,
  Description,
  Icon,
  MoreDetailsLink,
  OptionsDivider,
  PriceText,
  Title,
} from './ExperienceOptionItem.style';

interface Props extends RegistrationOption {
  index: number;
}

export function ExperienceOptionItem(props: Props) {
  const { items: cart, add: addCart, remove: removeCart } = useShoppingCart();
  const { change: setForm } = useForm();
  const { user } = useCurrentUser();

  const { openModal } = useModal();

  /** List all the registrations made in the shopping cart. */
  const registrations = React.useMemo(() => {
    return cart.filter(i => i.type === 'registration') as CartItem<ShoppingCartRegistrationData>[];
  }, [cart]);

  /** List all the attendees selected for the actual experience. */
  const attendees = React.useMemo(() => {
    return registrations.filter(i => i.option.id === props.id);
  }, [registrations, props.id]);

  const count = React.useMemo(() => attendees.length, [cart, props.id]);
  const counterName = React.useMemo(() => `options[${props.index}].counter`, [props.index]);

  const price: number = React.useMemo(() => {
    if (!props.pricingSettings) return 0;

    const pricingTiers = props.pricingSettings.pricingTiers?.items ?? [];
    const pricingEnabled = props.pricingSettings.pricingEnabled ?? true;

    if (!pricingEnabled) {
      return 0;
    }

    if (props.pricingSettings.pricingType === 'Fixed') {
      const [first] = pricingTiers;
      return first?.price ?? 0;
    }

    if (props.pricingSettings.pricingType === 'Scaled') {
      let price = 0;

      const now = DateTime.now();

      for (const tier of pricingTiers) {
        const startDiff = DateTime.fromISO(tier.startDate).diff(now, 'days');
        const endDiff = DateTime.fromISO(tier.endDate).diff(now, 'days');

        if (startDiff.days <= 0 && endDiff.days >= 0) {
          price = Number(tier.price);
        }
      }

      return price;
    }

    return 0;
  }, [props.pricingSettings]);

  const priceFormatted = React.useMemo(() => formatMoney(price, 2), [price]);

  React.useEffect(() => setForm(counterName, count), [count]);

  const handleIncrease = () => {
    if (props.id && props.name) {
      const payload: ShoppingCartRegistrationData = {
        type: 'registration',
        price,
        option: {
          id: props.id,
          name: props.name,
        },
      };

      if (registrations.length === 0) {
        payload.attendee = {
          firstName: user?.firstName ?? '',
          lastName: user?.lastName ?? '',
          email: '',
          phone: user?.phone?.number ?? '',
          city: user?.address?.city ?? '',
          state: user?.address?.state ?? '',
          address: user?.address?.street1 ?? '',
          zip: user?.address?.zip ?? '',
          birthday: user?.birthDate ?? '',
          reminders: false,
        };
      }

      addCart(payload);
    }
  };

  const handleDecrease = () => {
    const [last] = attendees.reverse();

    if (last) {
      removeCart(last);
    }
  };

  const openDetails = () => {
    openModal<RegistrationOption>(MODALS.REGISTRATION_DETAILS_MODAL, props);
  };

  return (
    <Container>
      <Content>
        <Icon />

        <Description>
          <Title>{props.name}</Title>

          <MoreDetailsLink onClick={openDetails}>More Details</MoreDetailsLink>
        </Description>
      </Content>

      <Content>
        <PriceText>{price > 0 ? priceFormatted : 'FREE'}</PriceText>

        <OptionsDivider />

        <CounterContainer>
          <Field
            name={counterName}
            value={count}
            component={CounterField}
            onIncrease={handleIncrease}
            onDecrease={handleDecrease}
          />
        </CounterContainer>
      </Content>
    </Container>
  );
}
