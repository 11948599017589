import React from 'react';
import { makeStyles } from '@mui/styles';
import { Grid, Typography } from '@mui/material';

const useStyles = makeStyles({
  gridPadding: {
    padding: '19px 32px 19px 32px',
  },
});

/** @deprecated */
export const TeamLandingFooter = () => {
  const classes = useStyles();

  return (
    <Grid
      sx={{ borderTop: '1px solid #E7E9EB' }}
      container
      justifyContent="space-between"
      marginTop="5px"
    >
      <Grid className={classes.gridPadding} item xs={3}>
        <Typography variant="caption">Powered by VIZSLA.com</Typography>
      </Grid>
      <Grid
        className={classes.gridPadding}
        item
        container
        xs={4}
        justifyContent="center"
        spacing={1}
      >
        <Grid item>
          <Typography variant="caption">Terms & Conditions</Typography>
        </Grid>
        <Grid item>
          <Typography variant="caption">Privacy Policy</Typography>
        </Grid>
        <Grid item>
          <Typography variant="caption">Report this Page</Typography>
        </Grid>
      </Grid>
      <Grid className={classes.gridPadding} container item xs={3} justifyContent="flex-end">
        <Typography variant="caption">{`© ${new Date().getFullYear()}`}</Typography>
      </Grid>
    </Grid>
  );
};
