import React, { useMemo } from 'react';
import { Avatar } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';

import { useAppAuth, useCurrentUser } from '@vizsla/hooks';

export function AvatarImage() {
  const { user } = useCurrentUser();
  const { isAuthenticated } = useAppAuth();

  const initials = useMemo(() => {
    const first = user?.firstName?.[0] ?? '';
    const second = user?.lastName?.[0] ?? '';

    return first + second;
  }, [user]);

  const avatarURL = useMemo(() => {
    return user?.avatar?.downloadUrl;
  }, [user]);

  if (!isAuthenticated) {
    return <AccountCircle />;
  }

  if (!avatarURL) {
    return <Avatar>{initials}</Avatar>;
  }

  return <Avatar src={avatarURL} />;
}
