import create from 'zustand';

import { Experience } from '@vizsla/graphql';

interface State {
  data?: Experience;
  loading: boolean;
}

interface Store extends State {
  setData(data: State['data']): void;
  setLoading(loading: boolean): void;

  clean(): void;
}

export const useSelectedExperience = create<Store>(set => {
  const initial: State = {
    data: undefined,
    loading: true,
  };

  return {
    ...initial,

    setData(data) {
      set({ data, loading: false });
    },

    setLoading(loading) {
      set({ loading });
    },

    clean() {
      set({ ...initial });
    },
  };
});
