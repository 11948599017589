import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import createStore from 'zustand';

import { buildUrl } from '@vizsla/utils';

import { LANDING_ROUTES, ROUTES } from 'src/constants';

import { useSelectedExperience } from '../experience';

interface Redirects {
  success?: string;
  startOver?: string;
}

interface State {
  redirects?: Redirects;
}

interface Store extends State {
  clean(): void;

  setRedirects(redirects: Redirects): void;
}

const useStore = createStore<Store>(setState => {
  const initial: State = {
    redirects: undefined,
  };

  return {
    ...initial,

    clean() {
      setState(initial, true);
    },

    setRedirects(redirects) {
      setState({ redirects });
    },
  };
});

export function useCheckoutPage() {
  const { data: current, setData: setExperience } = useSelectedExperience();
  const { redirects, setRedirects, clean } = useStore();

  function open(experience: { id: string }, redirects?: Redirects) {
    if (redirects) setRedirects(redirects);

    setExperience({ ...current, ...experience });
  }

  function openExperience(experience: { id: string }) {
    const success = buildUrl(LANDING_ROUTES.EXPERIENCE_THANKS, { experienceId: experience.id });
    const startOver = buildUrl(LANDING_ROUTES.EXPERIENCE_REGISTRATION, {
      experienceId: experience.id,
    });

    open(experience, { success, startOver });
  }

  function openTeam(team: { id: string; experience: { id: string } }) {
    const success = buildUrl(LANDING_ROUTES.TEAM_DONATION_THANKS, { teamId: team.id });
    const startOver = buildUrl(LANDING_ROUTES.TEAM_DONATION, { teamId: team.id });

    open(team.experience, { success, startOver });
  }

  function openFundraiser(fundraiser: { id: string; experience: { id: string } }) {
    const success = buildUrl(LANDING_ROUTES.FUNDRAISER_DONATION_THANKS, {
      fundraiserId: fundraiser.id,
    });

    const startOver = buildUrl(LANDING_ROUTES.FUNDRAISER_DONATION, {
      fundraiserId: fundraiser.id,
    });

    open(fundraiser.experience, { success, startOver });
  }

  const initializated = useMemo(() => Boolean(current), [current]);

  return {
    experience: current,
    redirects,

    initializated,

    open,
    openExperience,
    openTeam,
    openFundraiser,

    clean,
  };
}
