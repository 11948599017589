import React from 'react';
import { useParams } from 'react-router-dom';

import { CartItem, DonationFrecuency, ShoppingCartDonationData } from '@vizsla/types';
import { DonationForm, DonationFormValues, Form } from '@vizsla/components';
import { validateWithSchema } from '@vizsla/utils';
import { useCurrentUser, useNotification, useShoppingCart } from '@vizsla/hooks';

import { ExperiencePurchaseLayout } from 'src/layouts';
import { DONATION_FORM_SCHEMA } from 'src/constants/validationSchemas';
import {
  useCheckout,
  useCheckoutPage,
  useOpenExperiencePages,
  useSelectedExperience,
} from 'src/hooks';

import { Button, Buttons, Content } from './ExperienceDonationStep.style';

const INITIAL_VALUES: DonationFormValues = {
  frecuency: DonationFrecuency.ONE_TIME,
};

export function ExperienceDonationStep() {
  const [checkout] = useCheckout();
  const { items: cart, add: addCart, remove: removeCart } = useShoppingCart();
  const { data: experience } = useSelectedExperience();
  const { user } = useCurrentUser();
  const { openExperience: openCheckout } = useCheckoutPage();
  const { experienceId } = useParams();
  const { openRegistrationForm: openOptionsSelector, openAssetsForm } = useOpenExperiencePages();

  const toaster = useNotification();

  const donation = React.useMemo(() => {
    return cart.find(i => i.type === 'donation') as CartItem<ShoppingCartDonationData>;
  }, [cart]);

  const initialValues = React.useMemo(() => {
    return {
      ...INITIAL_VALUES,

      amount: donation?.price ?? INITIAL_VALUES.amount,
      message: donation?.message ?? INITIAL_VALUES.message,
    };
  }, [donation]);

  const validateSchema = (values: DonationFormValues) => {
    return validateWithSchema(DONATION_FORM_SCHEMA, values);
  };

  const handleStartOver = () => {
    openOptionsSelector(experienceId);
  };

  const handleBack = () => {
    openAssetsForm(experienceId);
  };

  const handleSubmit = (values: DonationFormValues) => {
    const price = values.amount ?? 0;
    const makeAnonymous = values.makeAnonymous ?? false;

    if (!experience?.id) {
      toaster.error(`Experience ID must be defined. Got ${typeof experience?.id}`);
      return;
    }

    if (!user?.id) {
      toaster.error(`User ID must be defined. Got ${typeof user?.id}`);
      return;
    }

    if (donation) {
      removeCart(donation);
    }

    addCart({
      type: 'donation',
      donor: { id: user.id },
      allocation: { type: 'experience', id: experience?.id },
      message: values.message,
      makeAnonymous,
      price,
    });

    openCheckout({ id: experience.id });
    checkout(experience.id);
  };
  const skipStep = () => {
    if (!experience?.id) {
      toaster.error(`Experience ID must be defined. Got ${typeof experience?.id}`);
      return;
    }
    if (donation) removeCart(donation);
    openCheckout({ id: experience.id });
    checkout(experience.id);
  };

  return (
    <ExperiencePurchaseLayout>
      <Form initialValues={initialValues} onSubmit={handleSubmit} validate={validateSchema}>
        {({ handleSubmit, invalid }) => (
          <Content>
            <DonationForm experienceId={experience?.id as string} />

            <Buttons>
              <Button variant="outlined" onClick={handleStartOver}>
                Start Over
              </Button>

              <Button variant="outlined" onClick={handleBack}>
                Back
              </Button>

              <Button variant="outlined" onClick={skipStep}>
                Skip
              </Button>

              <Button onClick={handleSubmit} disabled={invalid}>
                Continue
              </Button>
            </Buttons>
          </Content>
        )}
      </Form>
    </ExperiencePurchaseLayout>
  );
}
