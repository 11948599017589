import React from 'react';
import { useParams } from 'react-router-dom';
import { Checkbox, Grid, Skeleton, Typography } from '@mui/material';

import { useShoppingCart } from '@vizsla/hooks';
import { useGetAllFileListQuery, useGetSwagBagAssetsByOptionQuery } from '@vizsla/graphql';
import { Form } from '@vizsla/components';
import { FontSize } from '@vizsla/theme';

import { ExperiencePurchaseLayout as ExperiencePurchaseLayout } from 'src/layouts';
import { useOpenExperiencePages } from 'src/hooks';

import { Button, Buttons, Content } from './ExperienceSwagBagStep.style';
import { SwagBagName } from './SwagBagName';

export function ExperienceSwagBagStep() {
  const { experienceId } = useParams();
  const { items, add: addItem, remove: removeItem } = useShoppingCart();
  const {
    openAttendeesForm,
    openAssetsForm,
    openRegistrationForm: openOptionsSelector,
  } = useOpenExperiencePages();

  const optionIds = React.useMemo(() => {
    const registration = items.filter(item => item.type === 'registration');
    return registration.map(item => {
      if (item?.type === 'registration') {
        return item.option.id;
      }
      return '';
    });
  }, [items]);

  const {
    data,
    loading: loadingAssets,
    error,
  } = useGetSwagBagAssetsByOptionQuery({
    variables: {
      filter: {
        registrationOptionAId: {
          in: optionIds,
        },
      },
    },
  });

  const assets = React.useMemo(() => {
    return data?.assetByRegistrationOptionViewsList?.items || [];
  }, [data]);

  const pictureIds = React.useMemo(() => {
    return assets.map(asset => asset.picture as string) || [];
  }, [assets]);

  const { data: assetsPictures, loading: loadAssetsPictures } = useGetAllFileListQuery({
    variables: { filter: { id: { in: pictureIds } } },
  });

  const loading = React.useMemo(
    () => loadingAssets || loadAssetsPictures,
    [loadingAssets, loadAssetsPictures],
  );

  const cartData = React.useMemo(() => {
    const data = items.filter(item => item.type === 'registration');
    return data;
  }, [items]);

  const picturesData = React.useMemo(() => {
    return assetsPictures?.filesList?.items || [];
  }, [assetsPictures]);

  const finalData = assets.map((asset: any) => {
    const image = picturesData.filter(itF => itF.id === asset.picture);
    return { ...asset, uri: image[0]?.downloadUrl } as any;
  });

  const assetCount = optionId => {
    const count = items.map(item => item.type === 'swagBag' && item.swagBag.id === optionId);
    const countTotal = count.filter(item => item === true).length;
    return countTotal;
  };

  const addAsset = data => {
    addItem({
      type: 'swagBag',
      name: data?.name,
      price: 0,
      swagBag: {
        id: data?.id,
        name: data?.name,
        uri: data.uri,
        inventoryId: data?.inventoryId,
        pictureId: data?.picture,
        optionId: data?.registrationOptionAId,
        cartId: data?.cartId,
      },
    });

    assetCount(data?.id);
  };

  const removeAsset = asset => {
    if (asset) {
      removeItem({
        id: asset?.id,
      });
    }
  };

  const validateCheck = asset => {
    const swagBagOption = items.find(
      item =>
        item.type === 'swagBag' &&
        item.swagBag.pictureId === asset?.picture &&
        item.swagBag.optionId === asset?.registrationOptionAId &&
        item.swagBag.inventoryId === asset?.inventoryId &&
        item.swagBag.cartId === asset?.cartId,
    );
    return Boolean(swagBagOption);
  };

  const handleChange = asset => {
    const swagBagOption = items.find(
      item =>
        item.type === 'swagBag' &&
        item.swagBag.pictureId === asset?.picture &&
        item.swagBag.optionId === asset?.registrationOptionAId &&
        item.swagBag.cartId === asset?.cartId,
    );
    if (swagBagOption) {
      removeAsset(swagBagOption);
      addAsset(asset);
    } else {
      addAsset(asset);
    }
  };

  const renderItemSwagBag = (assetItem: any) => {
    return (
      <Grid id={assetItem?.id} container spacing={2} xs={12} flexDirection="row" mb="50px">
        <SwagBagName storeItem={assetItem} />

        <Grid justifyContent="center" alignItems="center" display="flex">
          <Checkbox checked={validateCheck(assetItem)} onChange={() => handleChange(assetItem)} />
        </Grid>
      </Grid>
    );
  };

  const renderListSwagBag = () => {
    return cartData.map(cartOption => {
      if (cartOption?.type === 'registration') {
        const optionList = finalData.filter(
          item => item.registrationOptionAId === cartOption?.option?.id,
        );
        return (
          <Grid key={cartOption.id} item xs={12}>
            <Typography variant="body1" align="center" pb={4} fontSize={FontSize.XL}>
              {cartOption?.option?.name}
            </Typography>
            <Grid item>
              {optionList.map(assetItem => {
                return renderItemSwagBag({ ...assetItem, cartId: cartOption.id });
              })}
            </Grid>
          </Grid>
        );
      }
      return null;
    });
  };

  const handleStartOver = () => {
    openOptionsSelector(experienceId);
  };

  const handleBack = () => {
    openAttendeesForm(experienceId);
  };

  const handleSubmit = () => {
    openAssetsForm(experienceId);
  };

  return (
    <ExperiencePurchaseLayout>
      <Form onSubmit={handleSubmit}>
        {form => (
          <Content>
            {loading && (
              <React.Fragment>
                <Skeleton variant="rectangular" height={48} />
                <Skeleton variant="rectangular" height={48} />
                <Skeleton variant="rectangular" height={48} />
              </React.Fragment>
            )}

            {!loading && renderListSwagBag()}

            <Buttons>
              <Button variant="outlined" onClick={handleStartOver}>
                Start Over
              </Button>

              <Button variant="outlined" onClick={handleBack}>
                Back
              </Button>

              <Button onClick={form.handleSubmit} disabled={form.invalid}>
                Continue
              </Button>
            </Buttons>
          </Content>
        )}
      </Form>
    </ExperiencePurchaseLayout>
  );
}
